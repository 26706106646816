import { createApi } from "@reduxjs/toolkit/query/react";

import {
  IInvoiceApi,
  IInvoiceCreateApi,
  IInvoiceUpdateApi,
} from "./interfaces";
import { baseQueryWithReauth } from "./base-query";
import dayjs from "dayjs";

export const busyBeeApiInvoices = createApi({
  reducerPath: "busy-bee-api-invoices",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Invoices"],
  endpoints: (builder) => ({
    getInvoices: builder.query<IInvoiceApi[], void>({
      query: () => `invoices`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Invoices", _id }) as const),
              { type: "Invoices", id: "LIST" },
            ]
          : [{ type: "Invoices", id: "LIST" }],
      transformResponse: (baseQueryReturnValue: IInvoiceApi[]) => {
        return baseQueryReturnValue.map((item) => {
          const sortedDates = item.paidDates
            .map((item) => new Date(item))
            .sort((a, b) => (dayjs(a).isAfter(dayjs(b), "day") ? 1 : -1));

          return {
            ...item,
            paidDates: sortedDates,
          };
        });
      },
    }),
    getInvoice: builder.query<IInvoiceApi, string>({
      query: (id) => `invoices/${id}`,
      providesTags: (result, error, _id) => [{ type: "Invoices", _id }],
    }),
    addInvoice: builder.mutation<IInvoiceApi, IInvoiceCreateApi>({
      query: (body) => ({
        url: `/invoices`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Invoices", id: "LIST" }],
    }),
    addInvoices: builder.mutation<IInvoiceApi, IInvoiceCreateApi[]>({
      query: (body) => ({
        url: `/invoices/create-many`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Invoices", id: "LIST" }],
    }),
    updateInvoice: builder.mutation<IInvoiceApi, IInvoiceUpdateApi>({
      query: ({ _id, ...body }) => ({
        url: `/invoices/${_id}`,
        method: "POST",
        body,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Invoices", _id }],
    }),
    removeInvoice: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/invoices/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, _id) => [{ type: "Invoices", _id }],
    }),
  }),
});

export const {
  useAddInvoiceMutation,
  useAddInvoicesMutation,
  useGetInvoiceQuery,
  useGetInvoicesQuery,
  useRemoveInvoiceMutation,
  useUpdateInvoiceMutation,
} = busyBeeApiInvoices;
