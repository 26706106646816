import React, { FC } from "react";
import { ReactComponent as PencilIcon } from "assets/streamline-light/interface-essential/edit/pencil.svg";
import {
  Container,
  NameContainer,
  DateContainer,
  Item,
  StudentsCountContainer,
  ActionsContainer,
} from "./teachers-list-styles";
import dayjs from "dayjs";
import { ITeacher } from "../../interface";
import { IconButton } from "../../../common/components/form/icon-button";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { toggleModalEditTeacher } from "../../store/teachers-slice";

interface IProps {
  teachers: ITeacher[];
}

const TeachersList: FC<IProps> = ({ teachers }) => {
  const dispatch = useAppDispatch();
  const onEditClick =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(toggleModalEditTeacher(id));
    };

  return (
    <Container role="list" className="divide-y divide-gray-100">
      {teachers.map((item) => (
        <Item
          key={item._id}
          className="flex items-center justify-between gap-x-6 hover:bg-gray-50 cursor-pointer active:bg-gray-100 select-none"
        >
          <NameContainer>{item.name}</NameContainer>
          <StudentsCountContainer>
            Учнів: {item.studentsCount}
          </StudentsCountContainer>
          <DateContainer>
            {dayjs(item.createdAt).format("HH:mm DD.MM.YYYY")}
          </DateContainer>
          <ActionsContainer>
            <IconButton
              onClick={onEditClick(item._id)}
              icon={PencilIcon}
              buttonColor={"#4F46E5FF"}
              buttonColorHover={"#3b34af"}
              color={"#fff"}
            />
            {/*<IconButton*/}
            {/*  icon={TrashIcon}*/}
            {/*  buttonColor={"#ff0000"}*/}
            {/*  buttonColorHover={"#b60000"}*/}
            {/*  color={"#fff"}*/}
            {/*/>*/}
          </ActionsContainer>
        </Item>
      ))}
    </Container>
  );
};

export { TeachersList };
