import { FC } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-add-student-styles";
import { FormStudent } from "../form-student/form-student";
import { useAddStudentMutation } from "../../../../services/busy-bee-api/busy-bee-api-students";
import { IStudentCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useDispatch } from "react-redux";
import { closeModalAddStudent } from "../../store/students-slice";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import {
  formStudentGetParentsFromValues,
  useFormStudentParents,
} from "../../hooks/useFormStudentParents";

const mobileWrapperStyles = {
  width: "100%",
  height: "100%",
};

const ModalAddStudent: FC = () => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { isModalAddStudentOpen } = useAppSelector((state) => state.students);

  const { parentsState, removeParent, addParent } = useFormStudentParents({
    isModalAddStudentOpen,
  });

  const onClose = () => {
    dispatch(closeModalAddStudent());
  };

  const [mutation] = useAddStudentMutation();
  const onAddStudent = async (values: IStudentCreateApi) => {
    onClose();
    const parents = formStudentGetParentsFromValues(values, parentsState);
    await mutation({
      name: values.name,
      teacherId: values.teacherId,
      phone: values.phone ?? "",
      parents: parents || [],
      visitingDays: values.visitingDays || [],
      visitedDates: values.visitedDates || [],
      isVPO: values.isVPO,
      isUBD: values.isUBD,
    });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isModalAddStudentOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Створити учня</Title>
        <FormStudent
          parents={parentsState}
          addParent={addParent}
          removeParent={removeParent}
          handleSubmit={onAddStudent}
        />
      </Container>
    </Modal>
  );
};

export { ModalAddStudent };
