import { MainButton } from "../form/main-button";
import styled from "styled-components";
import { device } from "../../../../style/theme";

export const LogoText = styled.h2`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
  margin-left: 12px;
`;

export const LogoutButton = styled(MainButton)`
  padding: 12px 24px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  color: rgb(255 255 255);

  @media ${device.tablet} {
    padding: 8px 12px;
  }
`;
