import { isNumber } from "lodash";

export const addCommas = (num?: string | number): string => {
  if (!num) {
    return "";
  }
  const numString = String(num).replace(".", ",");
  const withoutStartZero = Number(numString.split(",")[0]);
  const int = String(withoutStartZero).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const decimal = numString.split(",")[1];
  const isComma = numString.includes(",");

  return `${int}${isComma ? "," : ""}${decimal ? `${decimal}` : ""}`;
};
export const removeNonNumeric = (num?: string | number): string =>
  String(num).replace(/\D\./g, "");

// separate logic start
const addZeros = (num: string) => {
  if (num.length > 0) {
    if (!num.includes(",")) {
      return `${num},00`;
    }
    if (num.split(",")[1].length === 0) {
      return `${num}00`;
    }
    if (num.split(",")[1].length === 1) {
      return `${num}0`;
    }
    return num;
  }
  return num;
};

const numberFormat = (
  value: number | undefined,
  isDecimalNumber?: boolean,
): string => {
  if (!isNumber(value)) {
    return "";
  }
  const number = Number(value);
  const round = isDecimalNumber
    ? Math.round(number * 100) / 100
    : Math.round(number);

  const withSeparator = round.toLocaleString("de-DE");

  if (isDecimalNumber) {
    return addZeros(withSeparator);
  }

  return withSeparator;
};

export { numberFormat };
