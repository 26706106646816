import { FC, memo } from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "assets/streamline-light/interface-essential/form-validation/close.svg";

import Icon from "../icon";
import { hexToRGB } from "../../utils/hex-to-rgb";
import { device } from "../../../../style/theme";
import { useIsMobileSize } from "../../hooks/useIsMobileSize";

const Container = styled.div<{ position: "left" | "right"; bgColor?: string }>`
  padding: 16px;
  opacity: 0.9;
  border-radius: 3px;
  background-color: ${({ bgColor }) => bgColor || hexToRGB("#e5e9ec", 0.9)};
  position: absolute;
  ${({ position }) => (position === "left" ? `left: 0;` : "right: 0;")}
  top: 0;
  cursor: pointer;
  z-index: 1;
  @media ${device.tablet} {
    padding: 20px 16px;
    background-color: transparent;
  }
`;

interface IProps {
  handleClose: () => void;
  position: "left" | "right";
  color: string;
  bgColor?: string;
}

const CloseButtonBase: FC<IProps> = ({
  handleClose,
  position,
  color,
  bgColor,
}) => {
  const isMobileSize = useIsMobileSize();
  return (
    <Container bgColor={bgColor} position={position} onClick={handleClose}>
      <Icon
        icon={CloseIcon}
        width={isMobileSize ? 16 : 24}
        height={isMobileSize ? 16 : 24}
        color={color}
      />
    </Container>
  );
};

const CloseButton = memo(CloseButtonBase);

export { CloseButton };
