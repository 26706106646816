import React, { FC, useState } from "react";
import { ContentLayout } from "../../common/components/content-layout/content-layout";
import { InvoicesListFilters } from "../components/invoices-list/invoices-list-filters/invoices-list-filters";
import { InvoicesList } from "../components/invoices-list/invoices-list";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { openModalAddInvoices } from "../store/invoices-slice";
import { IInvoice } from "../interface";
import { ModalEditInvoice } from "../components/modal-invoice-mutation/modal-edit-invoice";
import { ModalStudentItem } from "../../students/components/modal-student-item/modal-student-item";
import { ModalAddInvoice } from "../components/modal-invoice-mutation/modal-add-invoice";
import { InvoicesDaySummary } from "../components/invoices-list/invoices-day-summary/invoices-day-summary";
import { DataLoader } from "../../common/components/data-loader/data-loader";
import { useGetAllResources } from "../../../services/busy-bee-api/hooks/useGetAllResources";

const InvoicesPage: FC = () => {
  const dispatch = useDispatch();
  const [day, setDay] = useState(0);
  const dayDate = dayjs().add(day, "day").toDate();

  const {
    teachers,
    invoices: invoicesList,
    students,
    isLoading,
  } = useGetAllResources();

  const invoices: IInvoice[] = invoicesList
    .filter((item) => dayjs(item.dateOfPayment).isSame(dayjs(dayDate), "day"))
    .map((item) => ({
      ...item,
      studentId: String(item.studentId),
      student:
        students.find((student) => student._id === item.studentId)?.name ?? "",
      teacher:
        teachers.find((teacher) => teacher._id === item.teacherId)?.name ?? "",
      amount: item.amount,
      dateOfPayment: item.dateOfPayment,
      paidDates: item.paidDates,
    }))
    .sort((a, b) =>
      dayjs(a.dateOfPayment).isAfter(dayjs(b.dateOfPayment)) ? -1 : 1,
    );

  const onAddNew = () => {
    dispatch(openModalAddInvoices());
  };

  return (
    <>
      <ContentLayout
        name="Платежі"
        button={{
          label: "Створити платіж",
          onClick: onAddNew,
        }}
        content={
          <>
            <InvoicesListFilters day={day} setDay={setDay} />

            {isLoading ? (
              <DataLoader />
            ) : (
              <>
                <InvoicesDaySummary invoices={invoices} />
                <InvoicesList invoices={invoices} />
              </>
            )}

            <ModalAddInvoice dateOfPayment={dayDate} />
            <ModalEditInvoice />
            <ModalStudentItem />
          </>
        }
      />
    </>
  );
};

export { InvoicesPage };
