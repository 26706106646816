import { useRef, useEffect, ReactNode, RefObject, CSSProperties } from "react";
import styled from "styled-components";

const useOutsideAlerter = (
  ref: RefObject<HTMLDivElement>,
  onOutsideClick: () => void,
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

interface IOutsideClickHandler {
  className?: string;
  display?: string;
  onOutsideClick: () => void;
  children?: ReactNode;
  style?: CSSProperties;
}

const Wrapper = styled.div<{ display: string }>`
  display: ${({ display }) => display};
`;

function OutsideClickHandler({
  className,
  display = "inline-block",
  onOutsideClick,
  children,
  style,
}: IOutsideClickHandler): JSX.Element {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(wrapperRef, onOutsideClick);

  return (
    <Wrapper
      style={style}
      className="outside-click-handler"
      display={display}
      ref={wrapperRef}
    >
      {children}
    </Wrapper>
  );
}

export { OutsideClickHandler };
