import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  RegisterOptions,
  useController,
  useFormContext,
} from "react-hook-form";
import { isNumber } from "lodash";

import {
  Container,
  ErrorContainer,
  ErrorMessage,
  InfoWrapper,
  InputAdornment,
  InputField,
  InputHide,
  InputWrapper,
  Label,
  LabelContainer,
  Spacer,
  SubLabel,
  Wrapper,
} from "./input-styles";
import { addCommas, removeNonNumeric } from "../../../utils/number-format";

interface IProps {
  name: string;
  label?: string;
  styleGuideErr?: boolean;
  sublabel?: string;
  placeholder?: string;
  backgroundColor?: string;
  valueProp?: string | number;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  autoComplete?: "on" | "off" | "nope" | "false" | "new-password";
  onFocus?: () => void;
  isAutoFocus?: boolean;
  onBlur?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  skipIcon?: boolean;
  isDisabled?: boolean;
  isColumn?: boolean;
  isDisabledWhite?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isPlaceholderCenter?: boolean;
  isLabelSaturated?: boolean;
  isNoBorder?: boolean;
  isPercent?: boolean;
  isSeparate?: boolean;
  isDecimalNumber?: boolean;
  isSaturatedBorder?: boolean;
  isValidateOnBlur?: boolean;
  inputAdornment?: string;
  handleChangeProp?: (value: number | string) => void;
}

const Input: FC<IProps> = ({
  label,
  styleGuideErr,
  sublabel,
  name,
  valueProp,
  placeholder,
  backgroundColor,
  rules,
  defaultValue,
  type,
  autoComplete = "on",
  onFocus,
  isAutoFocus,
  onOutsideClick,
  inputWrapperChildren,
  skipIcon = false,
  isLabelSaturated,
  isDisabled,
  isColumn,
  isDisabledWhite,
  isReadOnly,
  isRequired,
  isPlaceholderCenter,
  isNoBorder,
  isPercent,
  isSeparate,
  isDecimalNumber,
  isSaturatedBorder,
  isValidateOnBlur,
  inputAdornment,
  handleChangeProp,
}) => {
  const { trigger } = useFormContext();
  const {
    field: { ref, value: valueController, onChange, onBlur, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });
  const value = valueProp || valueController;

  const isValue =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    !Number.isNaN(value);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  const formatInput = (e: KeyboardEvent) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum =
        e.key === "e" ||
        e.key === "." ||
        e.key === "+" ||
        e.key === "-" ||
        (!isDecimalNumber && e.key === ",");
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum =
        e.keyCode === 69 ||
        e.keyCode === 190 ||
        e.keyCode === 187 ||
        e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const eventValue =
        Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
          ? e.target.value
          : e.target.valueAsNumber;
      if (isDecimalNumber) {
        const numberLength = Number(String(eventValue).split(".")?.[1]?.length);
        if (numberLength > 2) {
          return;
        }
      }
      if (isPercent && Number(eventValue) > 100) {
        return;
      }

      onChange(eventValue);

      if (handleChangeProp) {
        handleChangeProp(
          type === "number" ? Number(e.target.value) : e.target.value,
        );
      }
    },
    [handleChangeProp, isDecimalNumber, isPercent, onChange, type],
  );

  // separate logic start
  const addZeros = (num: string) => {
    if (num.length > 0 && isDecimalNumber) {
      if (!num.includes(",")) {
        return `${num},00`;
      }
      if (num.split(",")[1].length === 0) {
        return `${num}00`;
      }
      if (num.split(",")[1].length === 1) {
        return `${num}0`;
      }
      return num;
    }
    return num;
  };
  const separateRef = useRef<HTMLInputElement>(null);
  const defaultSeparateValue = addZeros(
    addCommas(
      removeNonNumeric(
        defaultValue ? Number(defaultValue) : value ? Number(value) : "",
      ),
    ),
  );
  const [separateValue, setSeparateValue] = useState(defaultSeparateValue);
  const [inputPosition, setPosition] = useState(0);
  const [commas, setCommas] = useState(
    (defaultSeparateValue.match(/\./g) || []).length,
  );
  useEffect(() => {
    if (isSeparate) {
      setSeparateValue(
        addZeros(
          addCommas(
            removeNonNumeric(
              defaultValue ? Number(defaultValue) : value ? Number(value) : "",
            ),
          ),
        ),
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isSeparate) {
      setSeparateValue(
        addZeros(
          addCommas(
            removeNonNumeric(
              valueController
                ? Number(valueController)
                : valueController
                  ? Number(valueController)
                  : "",
            ),
          ),
        ),
      );
    }
  }, [valueController]);

  const onSeparateChange = (e: ChangeEvent<HTMLInputElement>) => {
    const eventValue =
      Number.isNaN(e.target.value) || Number.isNaN(e.target.valueAsNumber)
        ? e.target.value
        : e.target.valueAsNumber;

    const eventValueString = String(eventValue)
      .replaceAll(".", "")
      .replaceAll(",", ".");
    const eventValueNumber = Number(eventValueString);

    if (Number.isNaN(eventValueNumber)) {
      return;
    }
    // check decimal length
    if (isDecimalNumber) {
      const decimalLength = Number(eventValueString.split(".")?.[1]?.length);
      if (decimalLength > 2) {
        return;
      }
    }

    // check percent value
    if (isPercent && eventValueNumber > 100) {
      return;
    }
    onChange(eventValueNumber);

    const valueWithCommas = addCommas(removeNonNumeric(eventValueString));
    setSeparateValue(valueWithCommas);

    // Stop cursor from jumping to end of input field
    const cursor = separateRef.current?.selectionStart;
    if (isNumber(cursor)) {
      const totalCommas = (valueWithCommas.match(/\./g) || []).length;
      if (separateValue.length - 2 > valueWithCommas.length) {
        setCommas(totalCommas);
        setPosition(cursor);
      } else if (commas > totalCommas) {
        // shift cursor to the left (a comma was removed)
        setCommas((state) => state - 1);
        setPosition(cursor - 1 < 0 ? 0 : cursor - 1);
      } else if (commas < totalCommas) {
        // shift cursor to the right (a comma was added)
        setCommas((state) => state + 1);
        setPosition(cursor + 1);
      } else {
        setPosition(cursor);
      }
    }

    if (handleChangeProp) {
      handleChangeProp(type === "number" ? eventValueNumber : e.target.value);
    }
  };

  useEffect(() => {
    if (isSeparate && document.activeElement === separateRef.current) {
      separateRef.current?.setSelectionRange(inputPosition, inputPosition);
    }
  }, [value, inputPosition]);

  const handleBlur = () => {
    onBlur();
    setSeparateValue(addZeros(separateValue));
    if (isValidateOnBlur) {
      trigger(name);
    }
  };
  const errorMessage = errors[name] ? String(errors[name]?.message) : "";

  return (
    <Container className="input-container" hasError={!!errors[name]}>
      <Wrapper className="input-wrapper" isColumn={isColumn} ref={wrapperRef}>
        <InfoWrapper>
          {label && (
            <LabelContainer className="label-container">
              <Label
                className="label"
                styleGuideErr={styleGuideErr}
                isLabelSaturated={isLabelSaturated}
              >
                {label}
                {isRequired && "*"}
              </Label>
              {sublabel && <SubLabel>{sublabel}</SubLabel>}
            </LabelContainer>
          )}
        </InfoWrapper>
        <InputWrapper>
          {isSeparate && (
            <InputHide
              type="number"
              id={name}
              ref={ref}
              value={value}
              onChange={() => {}}
            />
          )}
          <InputField
            id={isSeparate ? `${name} text` : name}
            ref={isSeparate ? separateRef : ref}
            isInputAdornment={!!inputAdornment}
            placeholder={placeholder ?? ""}
            type={isSeparate ? "text" : type}
            autoComplete={autoComplete}
            onFocus={onFocus}
            autoFocus={isAutoFocus}
            onChange={isSeparate ? onSeparateChange : handleChange}
            // onBlur={onBlur}
            hasError={!!errors[name]}
            onBlur={handleBlur}
            styleGuideErr={styleGuideErr}
            {...(type === "number" && {
              onKeyDown: formatInput,
            })}
            backgroundColor={backgroundColor}
            isNoBorder={isNoBorder}
            isValue={isValue}
            {...(type === "number" ? { inputMode: "numeric" } : {})}
            isDisabled={isDisabled}
            isDisabledWhite={isDisabledWhite}
            isPlaceholderCenter={isPlaceholderCenter}
            readOnly={isReadOnly}
            isSaturatedBorder={isSaturatedBorder}
            value={isSeparate ? separateValue : isValue ? value : ""}
            {...inputProps}
          />
          {inputAdornment && <InputAdornment>{inputAdornment}</InputAdornment>}
        </InputWrapper>
        {inputWrapperChildren}
      </Wrapper>

      {errorMessage && (
        <ErrorContainer className="field-error">
          <Spacer />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { Input };
