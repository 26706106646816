import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Header = styled.div<{ className: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 24px 32px;
  button {
    margin: 0 !important;
  }

  @media ${device.tablet} {
    padding: 12px;
  }
`;
