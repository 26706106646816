import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Container = styled.div`
  position: relative;
  padding: 48px;
  display: flex;
  flex-direction: column;

  & > button {
    width: fit-content;
  }
  & > button:last-child {
    margin-top: 24px;
  }

  @media ${device.tablet} {
    height: 100%;
    padding: 48px 18px 18px;
    & > button {
      width: 100%;
    }
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 12px;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;

export const SubTitle = styled.h4`
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 8px 0;

  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.regularText};
`;

export const ParentsList = styled.ul`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-grow: 1;
  }
`;

export const ParentsItem = styled.li`
  padding: 4px 0;
  gap: 12px;
  display: flex;

  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.regularText};
  margin-left: 14px;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    top: 14px;
    left: -14px;
    background-color: black;
    border-radius: 50%;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @media ${device.tablet} {
    flex-direction: column;
    button {
      width: 100%;
    }
  }
`;
