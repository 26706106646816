import { createApi } from "@reduxjs/toolkit/query/react";

import {
  ITeacherApi,
  ITeacherCreateApi,
  ITeacherUpdateApi,
} from "./interfaces";
import { baseQueryWithReauth } from "./base-query";

export const busyBeeApiTeachers = createApi({
  reducerPath: "busy-bee-api-teachers",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Teachers"],
  endpoints: (builder) => ({
    getTeachers: builder.query<ITeacherApi[], void>({
      query: () => `teachers`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Teachers", _id }) as const),
              { type: "Teachers", id: "LIST" },
            ]
          : [{ type: "Teachers", id: "LIST" }],
    }),
    getTeacher: builder.query<ITeacherApi, string>({
      query: (id) => `teachers/${id}`,
      providesTags: (result, error, _id) => [{ type: "Teachers", _id }],
    }),
    addTeacher: builder.mutation<ITeacherApi, ITeacherCreateApi>({
      query: (body) => ({
        url: `/teachers`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Teachers", id: "LIST" }],
    }),
    updateTeacher: builder.mutation<ITeacherApi, ITeacherUpdateApi>({
      query: ({ _id, ...patch }) => ({
        url: `/teachers/${_id}`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Teachers", _id }],
    }),
    removeTeacher: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/teachers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, _id) => [{ type: "Teachers", _id }],
    }),
  }),
});

export const {
  useAddTeacherMutation,
  useGetTeacherQuery,
  useGetTeachersQuery,
  useRemoveTeacherMutation,
  useUpdateTeacherMutation,
} = busyBeeApiTeachers;
