import { configureStore } from "@reduxjs/toolkit";
import { reducer as auth } from "./modules/auth/store/auth-slice";
import { reducer as invoices } from "./modules/invoices/store/invoices-slice";
import { reducer as students } from "./modules/students/store/students-slice";
import { reducer as teachers } from "./modules/teachers/store/teachers-slice";
import { busyBeeApiStudents } from "./services/busy-bee-api/busy-bee-api-students";
import { busyBeeApiInvoices } from "./services/busy-bee-api/busy-bee-api-invoices";
import { busyBeeApiAuth } from "./services/busy-bee-api/busy-bee-api-auth";
import { busyBeeApiTeachers } from "./services/busy-bee-api/busy-bee-api-teachers";

export const store = configureStore({
  reducer: {
    [busyBeeApiStudents.reducerPath]: busyBeeApiStudents.reducer,
    [busyBeeApiInvoices.reducerPath]: busyBeeApiInvoices.reducer,
    [busyBeeApiTeachers.reducerPath]: busyBeeApiTeachers.reducer,
    [busyBeeApiAuth.reducerPath]: busyBeeApiAuth.reducer,
    auth,
    invoices,
    students,
    teachers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(busyBeeApiStudents.middleware)
      .concat(busyBeeApiInvoices.middleware)
      .concat(busyBeeApiTeachers.middleware)
      .concat(busyBeeApiAuth.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
