import React, { FC } from "react";
import { ContentLayout } from "../../common/components/content-layout/content-layout";
import { Pagination } from "../../common/components/pagination";
import { usePagination } from "../../common/hooks/usePagination";
import { useQuery } from "../../common/hooks/useQuery";
import { useDispatch } from "react-redux";
import { TeachersList } from "../components/teachers-list/teachers-list";
import { openModalAddTeacher } from "../store/teachers-slice";
import { TeachersListFilters } from "../components/teachers-list-filters/teachers-list-filters";
import { ModalAddTeacher } from "../components/modal-add-teacher/modal-add-teacher";
import { ModalEditTeacher } from "../components/modal-edit-teacher/modal-edit-teacher";
import { DataLoader } from "../../common/components/data-loader/data-loader";
import { useGetAllResources } from "../../../services/busy-bee-api/hooks/useGetAllResources";
import { useFilterSortTeachers } from "../hooks/useFilterSortTeachers";

const TeachersPage: FC = () => {
  const dispatch = useDispatch();
  const { students, teachers: teachersList, isLoading } = useGetAllResources();

  const { teachers, setFilters, setSort } = useFilterSortTeachers({
    students,
    teachers: teachersList,
  });

  const onAddNew = () => {
    dispatch(openModalAddTeacher());
  };

  const pageSize = 10;
  const query = useQuery();
  const page = query.get("page");

  const { currentPage, pagesCount, onChangePage } = usePagination({
    itemsCount: teachers.length,
    pageSize,
    initPage: Number(page) || undefined,
  });

  const onSetFilters: React.Dispatch<React.SetStateAction<{ name: string }>> = (
    state,
  ) => {
    setFilters(state);
    onChangePage("page", 1);
  };

  const teachersPage = teachers.filter((item, i) => {
    const from = pageSize * currentPage - pageSize + 1;
    const to = pageSize * currentPage;
    i = i + 1;
    return i >= from && i <= to;
  });

  return (
    <ContentLayout
      name="Вчителі"
      button={{
        label: "Створити вчителя",
        onClick: onAddNew,
      }}
      content={
        <>
          <TeachersListFilters setFilters={onSetFilters} setSort={setSort} />

          {isLoading ? (
            <DataLoader />
          ) : (
            <>
              <TeachersList teachers={teachersPage} />
              <Pagination
                itemsCount={teachers.length}
                currentPage={currentPage}
                pagesCount={pagesCount}
                pageSize={pageSize}
                onChangePage={onChangePage}
              />
            </>
          )}

          <ModalAddTeacher />
          <ModalEditTeacher />
        </>
      }
    />
  );
};

export { TeachersPage };
