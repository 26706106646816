import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

      font-stretch: normal;
      font-style: normal;
      //line-height: 1.33;
      letter-spacing: 0.5px;
      
    ::-webkit-scrollbar {
      background-color: #fff;
      width: 10px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: #fff;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 8px;
      border: 2px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display:none;
    }

  }
  html, body, #root {
    min-height: 100svh;
  }
  body {
    overflow: overlay;
    &:has(.modal-open) {
      overflow: hidden !important;
    }
  }
`;

const CSSReset = (): JSX.Element => <GlobalStyle />;

export default CSSReset;
