import { useState, useEffect } from "react";
import { MAX_MOBILE_SIZE } from "../constants/max-mobile-size";

const useIsMobileSize = (): boolean => {
  const [isMobileSize, setIsMobileSize] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileSize(window.innerWidth <= MAX_MOBILE_SIZE);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobileSize;
};

export { useIsMobileSize };
