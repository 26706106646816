import { FC } from "react";
import {
  Container,
  Title,
  ErrorContainer,
  ErrorMessage,
  Spacer,
} from "./form-invoice-calendar-styles";
import { Calendar as ReactCalendar } from "react-calendar";
import dayjs from "dayjs";
import { LooseValue } from "react-calendar/dist/cjs/shared/types";
import { useController } from "react-hook-form";

interface IProps {
  label: string;
  value?: LooseValue;
  onChange?: (value: Date) => void;
  visitingDays?: string[];
  visitedDates?: Date[];
  paidDates?: Date[];
  newPaidDates?: Date[];
  isDisabled?: boolean;
}

const FormInvoiceCalendar: FC<IProps> = ({
  label,
  value,
  newPaidDates,
  onChange,

  visitingDays,
  visitedDates,
  paidDates,
  isDisabled,
}) => {
  const {
    formState: { errors },
  } = useController({
    name: "paidDates",
  });
  const errorMessage = errors["paidDates"] ? errors["paidDates"]?.message : "";

  const tileClassName = ({ date }: { date: Date }) => {
    const isVisited = visitedDates?.find((item) =>
      dayjs(date).isSame(item, "day"),
    );
    const isPaid = paidDates?.find((item) => dayjs(date).isSame(item, "day"));
    const isVisiting = !visitingDays?.includes(
      dayjs(date).locale("en").format("dd"),
    );
    const isNewPaidDate = newPaidDates?.find((item) =>
      dayjs(date).isSame(item, "day"),
    );
    return `${isNewPaidDate ? "new_paid" : ""} ${isVisited ? "visited" : ""} ${isPaid ? "paid" : ""} ${isVisiting ? "disabled" : "visiting"}`;
  };

  return (
    <Container isDisabled={isDisabled}>
      <Title>{label}</Title>
      <ReactCalendar
        value={value}
        onClickDay={onChange}
        tileClassName={tileClassName}
      />
      {errorMessage && (
        <ErrorContainer className="field-error">
          <Spacer />
          <ErrorMessage>{String(errorMessage)}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { FormInvoiceCalendar };
