import React, { FC } from "react";

import { ReactComponent as PencilIcon } from "assets/streamline-light/interface-essential/edit/pencil.svg";
import { ReactComponent as TrashIcon } from "assets/streamline-light/interface-essential/delete/bin.svg";

import {
  ActionsContainer,
  AmountContainer,
  Container,
  EmptyText,
  HeaderActions,
  HeaderAmount,
  HeaderContainer,
  HeaderPaidDates,
  HeaderStudent,
  HeaderTeacher,
  HeaderTime,
  InvoiceContainer,
  PaidDatesContainer,
  PaidDatesItemContainer,
  StudentContainer,
  TeacherContainer,
  TimeContainer,
} from "./invoices-list-styles";
import dayjs from "dayjs";
import { IInvoice } from "../../interface";
import { IconButton } from "../../../common/components/form/icon-button";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import {
  selectInvoice,
  toggleModalEditInvoice,
} from "../../store/invoices-slice";
import { toggleModalStudentItem } from "../../../students/store/students-slice";
import { useRemoveInvoiceMutation } from "../../../../services/busy-bee-api/busy-bee-api-invoices";

interface IProps {
  invoices: IInvoice[];
}

const InvoicesList: FC<IProps> = ({ invoices }) => {
  const isMobileSize = useIsMobileSize();
  const dispatch = useAppDispatch();
  const [mutation] = useRemoveInvoiceMutation();

  const onEditClick =
    (id: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      dispatch(toggleModalEditInvoice(id));
    };

  const onDeleteClick =
    (id: string) => async (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const isDelete = window.confirm("Ви впевнені що хочете видалити запис?");
      if (isDelete) {
        await mutation(id);
      }
    };

  const onInvoiceClick =
    (studentId: string, invoiceId: string) =>
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      dispatch(toggleModalStudentItem(studentId));
      dispatch(selectInvoice(invoiceId));
    };

  return (
    <Container>
      {invoices.length > 0 ? (
        invoices.map((item, i) => {
          const paidDatesList = item.paidDates.map((item) =>
            dayjs(item).format("DD.MM.YYYY"),
          );
          return (
            <>
              {i === 0 && !isMobileSize ? (
                <HeaderContainer>
                  <HeaderStudent>Учень</HeaderStudent>
                  <HeaderTeacher>Вчитель</HeaderTeacher>
                  <HeaderAmount>Сума</HeaderAmount>
                  <HeaderPaidDates>Оплачені дати</HeaderPaidDates>
                  <HeaderTime>Час</HeaderTime>
                  <HeaderActions />
                </HeaderContainer>
              ) : null}
              <InvoiceContainer
                onClick={onInvoiceClick(item.studentId as string, item._id)}
              >
                <StudentContainer>{item.student}</StudentContainer>
                <TeacherContainer>{item.teacher}</TeacherContainer>
                <AmountContainer>{item.amount} грн</AmountContainer>
                <PaidDatesContainer>
                  {paidDatesList.map((item) => (
                    <PaidDatesItemContainer>{item}</PaidDatesItemContainer>
                  ))}
                </PaidDatesContainer>
                <TimeContainer>
                  {dayjs(item.dateOfPayment).format("HH:mm:ss")}
                </TimeContainer>
                <ActionsContainer>
                  <IconButton
                    icon={PencilIcon}
                    buttonColor={"#4F46E5FF"}
                    buttonColorHover={"#3b34af"}
                    color={"#fff"}
                    onClick={onEditClick(item._id)}
                  />
                  <IconButton
                    icon={TrashIcon}
                    buttonColor={"#ff0000"}
                    buttonColorHover={"#b60000"}
                    color={"#fff"}
                    onClick={onDeleteClick(item._id)}
                  />
                </ActionsContainer>
              </InvoiceContainer>
            </>
          );
        })
      ) : (
        <EmptyText>Немає записів</EmptyText>
      )}
    </Container>
  );
};

export { InvoicesList };
