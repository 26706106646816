import styled from "styled-components";
import { hexToRGB } from "../../../common/utils/hex-to-rgb";
import { device } from "../../../../style/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderContainer = styled.div`
  font-weight: 700;
  padding: 14px;
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  & > div {
    font-family: Roboto, sans-serif;
    font-size: 18px;
  }
`;

export const HeaderStudent = styled.div`
  width: 25%;
`;

export const HeaderTeacher = styled.div`
  width: 10%;
`;

export const HeaderAmount = styled.div`
  width: 10%;
`;

export const HeaderPaidDates = styled.div`
  width: 35%;
`;

export const HeaderTime = styled.div`
  width: 10%;
  text-align: end;
`;

export const HeaderActions = styled.div`
  width: 10%;
`;

export const InvoiceContainer = styled.div`
  padding: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;
  border-bottom: 1px solid ${({ theme }) => hexToRGB(theme.borderColor, 0.5)};
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
  & > div {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 2;
  }
  &:hover {
    background-color: ${({ theme }) => hexToRGB(theme.borderColor, 0.5)};
  }

  @media ${device.tablet} {
    &:first-child {
      border-top: 1px solid ${({ theme }) => hexToRGB(theme.borderColor, 0.5)};
    }
    gap: 4px;
    flex-wrap: wrap;
  }
`;

export const StudentContainer = styled.div`
  font-weight: 700;
  width: 25%;
  order: 1;

  @media ${device.tablet} {
    width: 70%;
    order: 1;
    display: flex;
    align-items: center;
  }
`;

export const TeacherContainer = styled.div`
  width: 10%;
  order: 2;

  @media ${device.tablet} {
    width: 30%;
    order: 3;
  }
`;

export const AmountContainer = styled.div`
  width: 10%;
  order: 3;

  @media ${device.tablet} {
    width: 30%;
    order: 4;
  }
`;

export const PaidDatesContainer = styled.div`
  width: 35%;
  order: 4;
  display: flex;
  gap: 6px;
  flex-wrap: wrap;

  @media ${device.tablet} {
    order: 6;
    width: 100%;
  }
`;

export const PaidDatesItemContainer = styled.div`
  padding: 0 8px;
  border: 1px solid ${({ theme }) => hexToRGB(theme.blue, 0.3)};
  border-radius: 15px;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  height: 28px;

  font-family: Roboto, sans-serif;
  font-size: 14px;
`;

export const TimeContainer = styled.div`
  width: 10%;
  text-align: end;
  order: 5;

  @media ${device.tablet} {
    width: 30%;
    order: 5;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  width: 10%;
  order: 6;

  @media ${device.tablet} {
    width: 25%;
    order: 2;
  }
`;

export const EmptyText = styled.h3`
  margin-top: 24px;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-align: center;

  @media ${device.tablet} {
    font-size: 24px;
  }
`;
