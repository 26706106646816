import { useGetInvoicesQuery } from "../busy-bee-api-invoices";
import { useGetStudentsQuery } from "../busy-bee-api-students";
import { useGetTeachersQuery } from "../busy-bee-api-teachers";

const useGetAllResources = () => {
  const { data: invoicesRes, isFetching: isLoadingInvoices } =
    useGetInvoicesQuery();
  const invoices = invoicesRes || [];
  const { data: studentsRes, isFetching: isLoadingStudents } =
    useGetStudentsQuery();
  const students = studentsRes || [];
  const { data: teachersRes, isFetching: isLoadingTeachers } =
    useGetTeachersQuery();
  const teachers = teachersRes || [];

  const isLoading = isLoadingInvoices || isLoadingStudents || isLoadingTeachers;

  return { invoices, students, teachers, isLoading };
};

export { useGetAllResources };
