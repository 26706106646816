import styled from "styled-components";
import { device } from "../../../../../style/theme";
import "react-calendar/dist/Calendar.css";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  @media ${device.tablet} {
    padding: 12px;
    width: 100%;
  }
`;

export const CalendarContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 100;

  .react-calendar {
    border: none;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 17px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 17px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 17px -3px rgba(0, 0, 0, 0.75);
  }

  background-color: #fff;
`;

export const DateContainer = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
  //flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: 8px;

  button:first-child {
    margin-right: 8px;
  }

  @media ${device.tablet} {
    justify-content: space-between;
  }
`;

export const DateText = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  min-width: 160px;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.fileUploaderActiveHeader};
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;
