import { FC } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-edit-teacher-styles";
import { FormTeacher } from "../form-teacher/form-teacher";
import {
  useGetTeachersQuery,
  useUpdateTeacherMutation,
} from "../../../../services/busy-bee-api/busy-bee-api-teachers";
import { ITeacherCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { toggleModalEditTeacher } from "../../store/teachers-slice";

const mobileWrapperStyles = {
  width: "100%",
  minHeight: "100%",
};

const ModalEditTeacher: FC = () => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { isModalEditTeacherOpen } = useAppSelector((state) => state.teachers);

  const { data: teachersRes } = useGetTeachersQuery();
  const teachers = teachersRes || [];
  const teacher = teachers.find((item) => item._id === isModalEditTeacherOpen);

  const onClose = () => {
    dispatch(toggleModalEditTeacher(""));
  };

  const [mutation] = useUpdateTeacherMutation();
  const onAddTeacher = async (values: ITeacherCreateApi) => {
    await mutation({ _id: isModalEditTeacherOpen, name: values.name });
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={!!isModalEditTeacherOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Редагувати вчителя</Title>
        <FormTeacher defaultValues={teacher} handleSubmit={onAddTeacher} />
      </Container>
    </Modal>
  );
};

export { ModalEditTeacher };
