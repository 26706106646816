import { FC } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-add-teacher-styles";
import { FormTeacher } from "../form-teacher/form-teacher";
import { useAddTeacherMutation } from "../../../../services/busy-bee-api/busy-bee-api-teachers";
import { ITeacherCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { closeModalAddTeacher } from "../../store/teachers-slice";
import { useDispatch } from "react-redux";

const mobileWrapperStyles = {
  width: "100%",
  height: "100%",
};

const ModalAddTeacher: FC = () => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { isModalAddTeacherOpen } = useAppSelector((state) => state.teachers);

  const onClose = () => {
    dispatch(closeModalAddTeacher());
  };

  const [mutation] = useAddTeacherMutation();
  const onAddTeacher = async (values: ITeacherCreateApi) => {
    onClose();
    await mutation({ name: values.name });
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isModalAddTeacherOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Створити вчителя</Title>
        <FormTeacher handleSubmit={onAddTeacher} />
      </Container>
    </Modal>
  );
};

export { ModalAddTeacher };
