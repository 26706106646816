import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  isModalAddTeacherOpen: boolean;
  isModalEditTeacherOpen: string;
}

const initialState: IState = {
  isModalAddTeacherOpen: false,
  isModalEditTeacherOpen: "",
};

export const teachersSlice = createSlice({
  name: "teachers",
  initialState,
  reducers: {
    openModalAddTeacher(state) {
      state.isModalAddTeacherOpen = true;
    },
    closeModalAddTeacher(state) {
      state.isModalAddTeacherOpen = false;
    },
    toggleModalEditTeacher(state, action: PayloadAction<string>) {
      state.isModalEditTeacherOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openModalAddTeacher,
  closeModalAddTeacher,
  toggleModalEditTeacher,
} = teachersSlice.actions;

export const { name, reducer } = teachersSlice;

export default teachersSlice;
