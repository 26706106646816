import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../common/components/form/input/input";
import { Container } from "./students-list-filters-styles";
import { FormProvider, useForm } from "react-hook-form";
import { Select } from "../../../common/components/form/select/select";
import { useGetTeachersQuery } from "../../../../services/busy-bee-api/busy-bee-api-teachers";
import { sorts } from "../../../common/constants/sorts";
import { DebtStatus } from "../../interface";

interface IProps {
  setFilters: Dispatch<
    SetStateAction<{ name: string; teacherName: string; debtStatus: string }>
  >;
  setSort: Dispatch<SetStateAction<string>>;
}

const debtSelectOptions = [
  { label: "Всі", value: DebtStatus.All },
  { label: "Є борг (жовті дні)", value: DebtStatus.IsDebt },
  {
    label: "Є борг (жовті + червоні дні від 5)",
    value: DebtStatus.IsDebtWithRedDays,
  },
  { label: "Нема боргу", value: DebtStatus.IsNotDebt },
];

const StudentsListFilters: FC<IProps> = ({ setFilters, setSort }) => {
  const methods = useForm<{
    name: string;
    teacherName: string;
    sort: string;
    debtStatus: string;
  }>({
    defaultValues: {
      name: "",
      teacherName: "",
      sort: sorts[0],
      debtStatus: DebtStatus.All,
    },
  });

  const { data: teachersRes } = useGetTeachersQuery();
  const teachers = teachersRes || [];

  const [name, setName] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [debtStatus, setDebtStatus] = useState("");

  useEffect(() => {
    setFilters({
      name,
      teacherName,
      debtStatus,
    });
  }, [name, teacherName, debtStatus]);

  const teachersOptions = teachers
    .map((item) => ({
      label: item.name,
      value: item.name,
    }))
    .sort(function (a, b) {
      const textA = a.label.toUpperCase();
      const textB = b.label.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  teachersOptions.unshift({ label: "Всi", value: "" });

  return (
    <FormProvider {...methods}>
      <Container>
        <Select
          name="sort"
          isColumn
          isLabelSaturated
          label="Сортування"
          placeholder="Сортування"
          selectOptions={sorts.map((item) => ({ label: item, value: item }))}
          handleChangeProp={(value) => setSort(String(value))}
        />
        <Input
          name="name"
          isColumn
          isLabelSaturated
          label="Фiльтр по iменi"
          placeholder="Фiльтр по iменi"
          type="text"
          handleChangeProp={(value) => setName(String(value))}
        />
        <Select
          name="teacherName"
          isColumn
          isLabelSaturated
          label="Фiльтр за вчителем"
          placeholder="Фiльтр за вчителем"
          handleChangeProp={(value) => setTeacherName(String(value))}
          selectOptions={teachersOptions}
        />
        <Select
          name="debtStatus"
          isColumn
          isLabelSaturated
          label="Стан рахунку"
          placeholder="Стан рахунку"
          handleChangeProp={(value) => setDebtStatus(String(value))}
          selectOptions={debtSelectOptions}
        />
      </Container>
    </FormProvider>
  );
};

export { StudentsListFilters };
