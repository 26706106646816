import { createBrowserRouter, Navigate } from "react-router-dom";
import { Layout } from "../modules/common/components/layout";
import { InvoicesPage } from "../modules/invoices/pages/invoices-page";
import { StudentsPage } from "../modules/students/pages/students-page";
import { TeachersPage } from "../modules/teachers/pages/teachers-page";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { path: "/", element: <Navigate to="/teachers" /> },
      { path: "students", element: <StudentsPage /> },
      { path: "invoices", element: <InvoicesPage /> },
      { path: "teachers", element: <TeachersPage /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/teachers" />,
  },
]);

export { routes };
