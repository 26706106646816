import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Container = styled.div`
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  button {
    margin-top: 12px;
    width: fit-content;
    margin-left: auto;

    @media ${device.tablet} {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
