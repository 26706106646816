import { FC, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";
import LogoImg from "assets/logo.jpeg";
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../../auth/store/auth-slice";
import { LogoText, LogoutButton } from "./header-styles";

interface IProps {
  navigation: { name: string; href: string; isCurrent: boolean }[];
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const HeaderTemplate: FC<IProps> = ({ navigation }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const onLogout = () => {
    dispatch(setAccessToken(""));
    localStorage.setItem("accessToken", "");
  };

  return (
    <Disclosure as="nav" className="bg-gray-800">
      <>
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-16 items-center justify-between">
            <div
              className="absolute inset-y-0 left-0 flex items-center sm:hidden"
              onClick={() => setIsOpen((state) => !state)}
            >
              <button className="relative inline-flex items-center justify-center rounded-md p-2 bg-gray-700 text-white outline-none">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>
                {isOpen ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img className="h-8 w-auto" src={LogoImg} alt="Your Company" />
                <LogoText>Busy Bee</LogoText>
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium",
                        )
                      }
                      // aria-current={item.isCurrent ? "page" : undefined}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
            <LogoutButton
              backgroundColor={"rgb(55 65 81)"}
              backgroundHoverColor={"rgb(77,92,112)"}
              label="Вихiд"
              onClick={onLogout}
            />
          </div>
        </div>

        {isOpen && (
          // <Disclosure.Panel className="sm:hidden">
          <div className="space-y-1 px-2 pb-3 pt-2">
            {navigation.map((item) => {
              return (
                <NavLink
                  onClick={() => setIsOpen(false)}
                  to={item.href}
                  className={({ isActive }) => {
                    return classNames(
                      isActive
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    );
                  }}
                >
                  {item.name}
                </NavLink>
              );
            })}
          </div>
          // </Disclosure.Panel>
        )}
      </>
    </Disclosure>
  );
};

export { HeaderTemplate };
