import { MouseEvent } from "react";
import styled from "styled-components";

import { device } from "../../../../style/theme";
import { hexToRGB } from "../../utils/hex-to-rgb";

interface IProps {
  onClick?: (e?: MouseEvent) => void;
  label: string;
  isDisabled?: boolean;
}

const FlatButtonBase = styled.button<{
  isDisabled?: boolean;
}>`
  padding: 14px 24px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.ctaBlue};

  border: none;
  outline: none;

  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 1px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 225ms ease !important;
  &:hover {
    background-color: ${(props) => props.theme.ctaBlueHover};
  }
  @media ${device.tablet} {
    min-width: 100%;
    margin: 0 auto;
  }
  ${(props) =>
    props.isDisabled &&
    `
    background-color: ${props.theme.ctaDisabledBackground};
    pointer-events: none;
    color: ${hexToRGB("#000000", 0.3)};
  `}
`;

const FlatButton = ({ onClick, label, ...rest }: IProps): JSX.Element => (
  <FlatButtonBase onClick={onClick} {...rest}>
    {label}
  </FlatButtonBase>
);

export { FlatButton };
