import "core-js/features/array/flat-map";
import { memo, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../../../../style/theme";
import { LoginPage } from "../../pages/login";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { setAccessToken } from "../../store/auth-slice";
import { PageLoader } from "../../../common/components/page-loader/page-loader";
import { useLazyGetSchoolQuery } from "../../../../services/busy-bee-api/busy-bee-api-auth";
import { busyBeeApiInvoices } from "../../../../services/busy-bee-api/busy-bee-api-invoices";
import { busyBeeApiStudents } from "../../../../services/busy-bee-api/busy-bee-api-students";
import { busyBeeApiTeachers } from "../../../../services/busy-bee-api/busy-bee-api-teachers";

interface IProps {
  children: JSX.Element;
}

const AuthContainerBase = ({ children }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const [getSchool] = useLazyGetSchoolQuery();
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useAppSelector((state) => state.auth);

  const stopLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1200);
  };

  useEffect(() => {
    const accessTokenLS = localStorage.getItem("accessToken");
    dispatch(setAccessToken(accessTokenLS ?? ""));
    setTimeout(() => {
      getSchool();
      stopLoading();
    }, 50);
  }, []);

  useEffect(() => {
    if (!accessToken) {
      dispatch(busyBeeApiInvoices.util?.resetApiState());
      dispatch(busyBeeApiStudents.util?.resetApiState());
      dispatch(busyBeeApiTeachers.util?.resetApiState());
    }
  }, [accessToken]);

  if (isLoading) {
  }

  if (!accessToken) {
    return (
      <PageLoader isLoading={isLoading}>
        <ThemeProvider theme={defaultTheme}>
          <LoginPage />
        </ThemeProvider>
      </PageLoader>
    );
  }

  return <PageLoader isLoading={isLoading}>{children}</PageLoader>;
};

const AuthContainer = memo(AuthContainerBase);

export { AuthContainer };
