import React, { FC, ReactNode } from "react";
import {
  Container,
  ContainerChildren,
  LoadingSpinner,
} from "./page-loader-styles";

interface IProps {
  children: ReactNode;
  isLoading: boolean;
}

const PageLoader: FC<IProps> = ({ children, isLoading }) => {
  return (
    <>
      <Container isLoading={isLoading}>
        <LoadingSpinner>
          <div id="square1"></div>
          <div id="square2"></div>
          <div id="square3"></div>
          <div id="square4"></div>
          <div id="square5"></div>
        </LoadingSpinner>
      </Container>
      <ContainerChildren isLoading={isLoading}>{children}</ContainerChildren>
    </>
  );
};

export { PageLoader };
