import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { FC } from "react";

interface IProps {
  itemsCount: number;
  currentPage: number;
  pagesCount: number;
  pageSize: number;
  onChangePage: (type: "back" | "next" | "page", pageNumber?: number) => void;
}

const Pagination: FC<IProps> = ({
  itemsCount,
  currentPage,
  pagesCount,
  pageSize,
  onChangePage,
}) => {
  const pagesArray = Array.from(Array(pagesCount));
  let displayPagesArray = [];
  if (pagesCount < 6) {
    displayPagesArray = pagesArray.map((item, i) => String(i + 1));
  } else {
    displayPagesArray.push(1);
    if (currentPage - 2 > 1) {
      if (currentPage - 3 === 2) {
        displayPagesArray.push(currentPage - 2);
      } else if (currentPage - 3 === 1) {
        displayPagesArray.push(currentPage - 2);
      } else {
        displayPagesArray.push(currentPage - 2);
      }
    }
    if (currentPage - 1 > 1) {
      displayPagesArray.push(currentPage - 1);
    }
    if (currentPage > 1 && currentPage < pagesCount) {
      displayPagesArray.push(currentPage);
    }
    if (currentPage + 1 < pagesCount) {
      displayPagesArray.push(currentPage + 1);
    }
    if (currentPage + 2 < pagesCount) {
      if (currentPage + 3 === pagesCount - 1) {
        displayPagesArray.push(currentPage + 2);
      } else if (currentPage + 3 === pagesCount) {
        displayPagesArray.push(currentPage + 2);
      } else {
        displayPagesArray.push(currentPage + 2);
      }
    }
    displayPagesArray.push(pagesCount);
  }

  const from = currentPage * pageSize - pageSize + 1;
  const to =
    currentPage * pageSize <= itemsCount ? currentPage * pageSize : itemsCount;
  const currentPageStyles =
    "cursor-pointer relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600";
  const commonPageStyles =
    "cursor-pointer relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0";
  const dotsPageStyles =
    "relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0";

  return (
    <div className="select-none flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden pb-5">
        <div
          className="cursor-pointer relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => onChangePage("back")}
        >
          Попередня
        </div>

        <div className="flex items-center">
          <p className="text-sm text-gray-700">
            <span className="font-medium">{from}</span> до{" "}
            <span className="font-medium">{to}</span> з{" "}
            <span className="font-medium">{itemsCount}</span>
          </p>
        </div>
        <div
          className="cursor-pointer relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
          onClick={() => onChangePage("next")}
        >
          Наступна
        </div>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Показано <span className="font-medium">{from}</span> до{" "}
            <span className="font-medium">{to}</span> з{" "}
            <span className="font-medium">{itemsCount}</span> результатів
          </p>
        </div>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <div
              className="cursor-pointer relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => onChangePage("back")}
            >
              {/*<span className="sr-only">Попередня</span>*/}
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </div>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            {displayPagesArray.map((item) => (
              <div
                key={item}
                aria-current="page"
                className={
                  item === "..."
                    ? dotsPageStyles
                    : currentPage === Number(item)
                      ? currentPageStyles
                      : commonPageStyles
                }
                onClick={() => onChangePage("page", Number(item))}
              >
                {item}
              </div>
            ))}
            <div
              className="cursor-pointer relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              onClick={() => onChangePage("next")}
            >
              {/*<span className="sr-only">Наступна</span>*/}
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export { Pagination };
