import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { RootState } from "../../store";
import { setAccessToken } from "../../modules/auth/store/auth-slice";

const baseUrl = process.env.REACT_APP_BUSY_BEE_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.accessToken;

    // If we have a token set in state, let's assume that we should be passing it.
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    // const refreshResult = await baseQuery("/refreshToken", api, extraOptions);
    // if (refreshResult.data) {
    //   // store the new token
    //   api.dispatch(tokenReceived(refreshResult.data));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    //   api.dispatch(loggedOut());
    // }
    api.dispatch(setAccessToken(""));
  }
  return result;
};

export { baseQuery, baseQueryWithReauth };
