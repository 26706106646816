import styled from "styled-components";
import { device } from "../../../../style/theme";
import { MainButton } from "../../../common/components/form/main-button";
import { SecondaryButton } from "../../../common/components/form/secondary-button";

export const Container = styled.div`
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const ParentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-height: 500px;
  overflow: auto;

  @media ${device.tablet} {
    height: auto;
    flex-grow: 1;
  }
`;

export const ParentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;

  button {
    background-color: #ececec;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    color: black;

    &:hover {
      background-color: darkgrey;
    }
  }
  i {
    width: 12px;
    height: 12px;
  }
`;

export const ParentInputsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddParentButton = styled(SecondaryButton)`
  width: fit-content;
  margin-left: auto;

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;

export const SubmitButton = styled(MainButton)`
  margin-top: 12px;
  width: fit-content;
  margin-left: auto;

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
