import { DebtStatus } from "../interface";
import dayjs from "dayjs";
import { sorts } from "../../common/constants/sorts";
import { useState } from "react";
import {
  IInvoiceApi,
  IStudentApi,
  ITeacherApi,
} from "../../../services/busy-bee-api/interfaces";

interface IProps {
  students: IStudentApi[];
  teachers: ITeacherApi[];
  invoices: IInvoiceApi[];
}

const dayOfWeek: Record<string, number> = {
  Su: 0,
  Mo: 1,
  Tu: 2,
  We: 3,
  Th: 4,
  Fr: 5,
  Sa: 6,
};

const useFilterSortStudents = ({ students, teachers, invoices }: IProps) => {
  const [sort, setSort] = useState(sorts[0]); // А-Я, Я-А
  const [filters, setFilters] = useState({
    name: "",
    teacherName: "",
    debtStatus: "",
  });

  if (filters.name) {
    students = students.filter((item) =>
      item.name.toLowerCase().includes(filters.name.toLowerCase()),
    );
  }

  let studentsWithTeacher = students.map((student) => ({
    ...student,
    teacher:
      teachers.find((teacher) => teacher._id === student.teacherId)?.name ?? "",
  }));

  if (filters.teacherName) {
    studentsWithTeacher = studentsWithTeacher.filter((item) =>
      item.teacher.includes(filters.teacherName),
    );
  }

  if (filters.debtStatus !== DebtStatus.All) {
    studentsWithTeacher = studentsWithTeacher.filter((item) => {
      const studentInvoices = invoices.filter(
        (invoice) => invoice.studentId === item._id,
      );
      const studentPaidDates = studentInvoices
        .map((item) => item.paidDates)
        .flat();
      const isDebtDate = !!item.visitedDates.filter(
        (item) =>
          !studentPaidDates.find((paidDate) =>
            dayjs(item).isSame(dayjs(paidDate), "day"),
          ),
      ).length;

      // is debt with red days
      // 1. create array of all days started from 01 February till today
      // 2. check the same to isDebtDate filter
      const allVisitingDates: Date[] = [];
      // numbers visiting days
      const visitingDaysNumbers = item.visitingDays.map(
        (item) => dayOfWeek[item],
      );
      const now = dayjs();
      visitingDaysNumbers.forEach((item) => {
        // 01.02.2024
        let firstDayOfVisiting = dayjs()
          .set("y", 2024)
          .set("M", 1)
          .set("date", 1);
        // find first day of visiting
        while (firstDayOfVisiting.get("day") !== item) {
          firstDayOfVisiting = firstDayOfVisiting.add(1, "day");
        }
        // create all visiting dates
        while (
          firstDayOfVisiting.isBefore(now) ||
          firstDayOfVisiting.isSame(now, "day")
        ) {
          allVisitingDates.push(firstDayOfVisiting.toDate());
          firstDayOfVisiting = firstDayOfVisiting.add(7, "d");
        }
      });

      const isDebtWithRedDate =
        allVisitingDates.filter(
          (item) =>
            !studentPaidDates.find((paidDate) =>
              dayjs(item).isSame(dayjs(paidDate), "day"),
            ),
        ).length >= 5;

      return filters.debtStatus === DebtStatus.IsDebt
        ? isDebtDate
        : filters.debtStatus === DebtStatus.IsDebtWithRedDays
          ? isDebtWithRedDate
          : !isDebtDate;
    });
  }

  if (sort === sorts[0]) {
    studentsWithTeacher = studentsWithTeacher.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1,
    );
  } else {
    const sorted = studentsWithTeacher.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    if (sort !== sorts[1]) {
      studentsWithTeacher = sorted.reverse();
    } else {
      studentsWithTeacher = sorted;
    }
  }

  return { students: studentsWithTeacher, setSort, setFilters };
};

export { useFilterSortStudents };
