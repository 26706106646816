import React from "react";
import { HeaderTemplate } from "./templates/header";

const Header = () => {
  const navigation = [
    // { name: "Home", href: "", isCurrent: true },
    { name: "Вчителі", href: "teachers", isCurrent: false },
    { name: "Учні", href: "students", isCurrent: false },
    { name: "Платежі", href: "invoices", isCurrent: true },
  ];

  return <HeaderTemplate navigation={navigation} />;
};

export { Header };
