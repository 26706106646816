import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { RegisterOptions, useController } from "react-hook-form";

import {
  CalendarContainer,
  Container,
  InfoWrapper,
  InputAdornment,
  InputField,
  InputWrapper,
  Label,
  LabelContainer,
  SubLabel,
  Wrapper,
} from "./datepicker-styles";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import { ErrorContainer, ErrorMessage, Spacer } from "../input/input-styles";
import { Value } from "react-calendar/dist/cjs/shared/types";

interface IProps {
  name: string;
  label?: string;
  styleGuideErr?: boolean;
  sublabel?: string;
  placeholder?: string;
  backgroundColor?: string;
  valueProp?: string | number;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  autoComplete?: "on" | "off" | "nope" | "false" | "new-password";
  onFocus?: () => void;
  isAutoFocus?: boolean;
  onBlur?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  isDisabled?: boolean;
  isColumn?: boolean;
  isDisabledWhite?: boolean;
  isRequired?: boolean;
  isPlaceholderCenter?: boolean;
  isLabelSaturated?: boolean;
  isNoBorder?: boolean;
  isSaturatedBorder?: boolean;
  inputAdornment?: string;
  handleChangeProp?: (value: Value) => void;
}

const Datepicker: FC<IProps> = ({
  label,
  styleGuideErr,
  sublabel,
  name,
  valueProp,
  placeholder,
  backgroundColor,
  rules,
  defaultValue,
  type,
  autoComplete = "on",
  onFocus,
  isAutoFocus,
  onOutsideClick,
  isLabelSaturated,
  isDisabled,
  isColumn,
  isRequired,
  isPlaceholderCenter,
  isNoBorder,
  isSaturatedBorder,
  inputAdornment,
  handleChangeProp,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const {
    field: { ref, value: valueController, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });
  const value = valueProp || valueController;

  const isValue =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    !Number.isNaN(value);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        setOpenCalendar(false);
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  const errorMessage = errors[name] ? String(errors[name]?.message) : "";

  return (
    <Container className="input-container" hasError={!!errors[name]}>
      <Wrapper className="input-wrapper" isColumn={isColumn} ref={wrapperRef}>
        <InfoWrapper>
          {label && (
            <LabelContainer className="label-container">
              <Label
                className="label"
                styleGuideErr={styleGuideErr}
                isLabelSaturated={isLabelSaturated}
              >
                {label}
                {isRequired && "*"}
              </Label>
              {sublabel && <SubLabel>{sublabel}</SubLabel>}
            </LabelContainer>
          )}
        </InfoWrapper>
        <InputWrapper>
          <InputField
            isInputAdornment={!!inputAdornment}
            placeholder={placeholder ?? ""}
            type={type}
            onClick={() => setOpenCalendar(true)}
            autoComplete={autoComplete}
            onFocus={onFocus}
            autoFocus={isAutoFocus}
            hasError={!!errors[name]}
            styleGuideErr={styleGuideErr}
            isDisabled={isDisabled}
            backgroundColor={backgroundColor}
            isNoBorder={isNoBorder}
            isValue={isValue}
            isPlaceholderCenter={isPlaceholderCenter}
            readOnly
            isSaturatedBorder={isSaturatedBorder}
            value={isValue ? dayjs(value).format("DD.MM.YYYY") : ""}
            {...inputProps}
          />
          {inputAdornment && <InputAdornment>{inputAdornment}</InputAdornment>}
        </InputWrapper>
        {isOpenCalendar && (
          <CalendarContainer>
            <Calendar
              value={value}
              onChange={(value) => {
                if (handleChangeProp) {
                  handleChangeProp(value);
                }
                setOpenCalendar(false);
                onChange(value);
              }}
            />
          </CalendarContainer>
        )}
      </Wrapper>
      {errorMessage && (
        <ErrorContainer className="field-error">
          <Spacer />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { Datepicker };
