import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider } from "react-router-dom";
import { routes } from "./routes/routes";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "./style/theme";
import CSSReset from "./style/reset";
import { store } from "./store";
import { AuthContainer } from "./modules/auth/components/auth-container/auth-container";
import dayjs from "dayjs";
require("dayjs/locale/uk");
dayjs.locale("uk");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <CSSReset />
      <ThemeProvider theme={defaultTheme}>
        <AuthContainer>
          <RouterProvider router={routes} />
        </AuthContainer>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
