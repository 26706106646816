import { FC } from "react";
import { Container } from "./form-teacher-styles";
import { Input } from "../../../common/components/form/input/input";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { MainButton } from "../../../common/components/form/main-button";
import { ITeacherCreateApi } from "../../../../services/busy-bee-api/interfaces";

interface IProps {
  defaultValues?: ITeacherCreateApi;
  handleSubmit: (values: ITeacherCreateApi) => Promise<void>;
}

const FormTeacher: FC<IProps> = ({ defaultValues, handleSubmit }) => {
  const methods = useForm<ITeacherCreateApi>({ defaultValues });
  const onSubmit: SubmitHandler<ITeacherCreateApi> = async (data) => {
    await handleSubmit(data);
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Input
            name="name"
            label="Ім'я вчителя"
            placeholder="Ім'я вчителя"
            rules={{ required: "Обов'язкове поле" }}
          />

          <MainButton label="Зберегти" type="submit" />
        </form>
      </FormProvider>
    </Container>
  );
};

export { FormTeacher };
