import { useContext } from 'react';
// import ReactTooltip from 'react-tooltip';
import styled, { ThemeContext } from 'styled-components';
// import Icon from '../ui/icon';

const Container = styled.div<{ maxWidth?: number; textAlign?: string; noMaxWidth?: boolean }>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 20)}px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  ${(props) => props.noMaxWidth && 'max-width: unset;'}
  .__react_component_tooltip.show {
    opacity: 1;
  }
  .extraClass {
    pointer-events: auto !important;
    &:hover {
      visibility: visible !important;
    }
  }
`;

interface IProps {
  children: React.ReactNode;
  id: string;
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  position?: 'top' | 'right' | 'bottom' | 'left';
  stayOnHover?: boolean;
  isOnClick?: boolean;
  clickable?: boolean;
  width?: number;
  height?: number;
  color?: string;
  maxWidth?: number;
  noMaxWidth?: boolean;
  isDisabled?: boolean;
  content?: React.ReactNode;
  className?: string;
  textAlign?: string;
}
const Tooltip = ({
  children,
  id,
  icon: TooltipIcon,
  position = 'top',
  stayOnHover = false,
  isOnClick = false,
  clickable = false,
  width = 20,
  height = 20,
  color,
  maxWidth,
  noMaxWidth,
  isDisabled,
  content,
  className,
  textAlign,
}: IProps): JSX.Element => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container maxWidth={maxWidth} noMaxWidth={noMaxWidth} textAlign={textAlign}>
      <div data-tip data-for={id} id={`${id.replaceAll('.', '-')}-icon`}>
        {/*{TooltipIcon && (*/}
        {/*  <Icon*/}
        {/*    icon={TooltipIcon}*/}
        {/*    width={width}*/}
        {/*    height={height}*/}
        {/*    color={color ?? themeContext.blue}*/}
        {/*  />*/}
        {/*)}*/}
        {content ?? null}
      </div>
      {/*<ReactTooltip*/}
      {/*  {...(stayOnHover && { delayHide: 1000 })}*/}
      {/*  place={position}*/}
      {/*  {...(stayOnHover && { className: 'extraClass' })}*/}
      {/*  {...(isOnClick && { event: 'click' })}*/}
      {/*  id={id}*/}
      {/*  effect="solid"*/}
      {/*  type="light"*/}
      {/*  borderColor="#d9d9d9"*/}
      {/*  border*/}
      {/*  disable={isDisabled}*/}
      {/*  className={`${className} tooltip-container`}*/}
      {/*  clickable={clickable}*/}
      {/*>*/}
      {/*  {children}*/}
      {/*</ReactTooltip>*/}
    </Container>
  );
};

export { Tooltip };
