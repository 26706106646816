import styled from "styled-components";
import { CSSProperties, FunctionComponent, MouseEvent } from "react";

import { BaseButton } from "./base-button";
// import { ButtonLoader } from './button-loader';
import Icon from "../icon";

interface IProps {
  label: string;
  type?: "submit" | "button";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  customLoaderImage?: string;
  backgroundColor?: string;
  backgroundHoverColor?: string;
  fluid?: boolean;
  centered?: boolean;
  margin?: string;
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  loader?: boolean;
  id?: string;
  icon?: FunctionComponent;
  iconStyle?: CSSProperties;
  style?: CSSProperties;
  iconWidth?: number;
  iconHeight?: number;
}

const MainButtonBase = styled(BaseButton)<Omit<IProps, "label">>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.backgroundColor || props.theme.ctaBlue};
  color: ${(props) => props.color || "#ffffff"};
  transition:
    background-color 225ms ease,
    padding 225ms ease !important;
  border: none;
  text-transform: uppercase;
  ${({ isFullWidth }) => isFullWidth && "width: 100%;"}
  & > img {
    margin-right: 8px;
  }
  &:hover {
    background-color: ${(props) =>
      props.backgroundHoverColor || props.theme.ctaBlueHover};
    border-color: ${(props) =>
      props.backgroundHoverColor || props.theme.ctaBlueHover};
  }
  ${(props) =>
    props.isDisabled &&
    `
    background-color: ${props.theme.ctaDisabledBackground};
    pointer-events: none;
    cursor: default;
    color: rgba(0,0,0,0.3);
  `}
  ${(props) =>
    props.isLoading &&
    `padding-right: 3.6rem !important; pointer-events: none !important;`}
  ${(props) => props.centered && `justify-content: center;`}
`;

const MainButton = ({
  label,
  loader,
  customLoaderImage,
  icon,
  iconStyle,
  iconWidth,
  iconHeight,
  ...rest
}: IProps): JSX.Element => (
  <MainButtonBase {...rest}>
    {/*{loader &&*/}
    {/*  (customLoaderImage ? (*/}
    {/*    <img src={customLoaderImage} alt="loader" />*/}
    {/*  ) : (*/}
    {/*    <ButtonLoader*/}
    {/*      isLoading={rest.isLoading ?? false}*/}
    {/*      isDisabled={rest.isDisabled ?? false}*/}
    {/*      color={rest.color}*/}
    {/*    />*/}
    {/*  ))}*/}
    {icon && (
      <Icon
        style={{
          margin: "0 12px 0 0",
          // ...iconStyle,
        }}
        icon={icon}
        height={iconWidth || 24}
        width={iconHeight || 24}
        color={rest.color}
      />
    )}
    {label}
  </MainButtonBase>
);

export { MainButton };
