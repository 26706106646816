import styled from "styled-components";
import { device } from "../../../../style/theme";
import { MainButton } from "../../../common/components/form/main-button";

export const Container = styled.div`
  width: 100%;
  form {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`;

export const InputsCalendarContainer = styled.div`
  display: flex;
  gap: 12px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 400px;

  .input-container {
    flex: 0;
  }
`;

export const SubmitButton = styled(MainButton)`
  width: fit-content;
  margin-left: auto;
  margin-top: 12px;

  @media ${device.tablet} {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
