import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Input } from "../../../common/components/form/input/input";
import { FormProvider, useForm } from "react-hook-form";
import { Container } from "./teachers-list-filters-styles";
import { Select } from "../../../common/components/form/select/select";
import { sorts } from "../../../common/constants/sorts";

interface IProps {
  setFilters: Dispatch<SetStateAction<{ name: string }>>;
  setSort: Dispatch<SetStateAction<string>>;
}

const TeachersListFilters: FC<IProps> = ({ setFilters, setSort }) => {
  const methods = useForm<{ name: string; sort: string }>({
    defaultValues: { name: "", sort: sorts[0] },
  });
  const [name, setName] = useState("");

  useEffect(() => {
    setFilters({
      name,
    });
  }, [name]);

  return (
    <FormProvider {...methods}>
      <Container>
        <Select
          name="sort"
          isColumn
          isLabelSaturated
          label="Сортування"
          placeholder="Сортування"
          selectOptions={sorts.map((item) => ({ label: item, value: item }))}
          handleChangeProp={(value) => setSort(String(value))}
        />
        <Input
          name="name"
          isColumn
          isLabelSaturated
          label="Фiльтр по iменi"
          placeholder="Фiльтр по iменi"
          type="text"
          handleChangeProp={(value) => setName(String(value))}
        />
      </Container>
    </FormProvider>
  );
};

export { TeachersListFilters };
