import { FC } from "react";
import LogoImg from "assets/logo.jpeg";
import CSSReset from "../../../style/reset";

import {
  CardGroup,
  Container,
  FormCard,
  FormDescription,
  FormHeader,
  Logo,
  LogoCard,
} from "./login-styles";
import { LoginForm } from "../components/login-form/login-form";

const LoginPage: FC = () => {
  return (
    <Container>
      <CSSReset />
      <CardGroup>
        <FormCard>
          <FormHeader>Вхід до аккаунту</FormHeader>
          <FormDescription>
            Використовуйте пароль та логін для входу
          </FormDescription>
          <LoginForm />
        </FormCard>
        <LogoCard>
          <Logo src={LogoImg} />
        </LogoCard>
      </CardGroup>
    </Container>
  );
};

export { LoginPage };
