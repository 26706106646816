import { FC, useEffect, useLayoutEffect, useState } from "react";

import {
  Container,
  InputsCalendarContainer,
  InputsContainer,
  SubmitButton,
} from "./form-invoice-styles";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete } from "../../../common/components/form/autocomplete/autocomplete";
import { IInvoiceCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { FormInvoiceCalendar } from "./form-invoice-calendar/form-invoice-calendar";
import { Input } from "../../../common/components/form/input/input";
import { Datepicker } from "../../../common/components/form/datepicker/datepicker";
import dayjs from "dayjs";
import { Select } from "../../../common/components/form/select/select";
import { useGetAllResources } from "../../../../services/busy-bee-api/hooks/useGetAllResources";

interface IProps {
  newPaidDates: Date[];
  onChangeNewPaidDates: (value: Date) => void;
  defaultValues?: Partial<IInvoiceCreateApi>;
  handleSubmit: (values: IInvoiceCreateApi) => Promise<void>;
}

const typeOfPaymentOptions = [
  { label: "Карткою", value: "card" },
  { label: "ФОП", value: "FOP" },
  { label: "Готівкою", value: "cash" },
];

const FormInvoice: FC<IProps> = ({
  newPaidDates,
  onChangeNewPaidDates,
  defaultValues,
  handleSubmit,
}) => {
  const { teachers, invoices, students } = useGetAllResources();

  const [teacherId, setTeacher] = useState("");
  const [studentId, setStudent] = useState("");

  const filteredStudents = teacherId
    ? students.filter((item) => item.teacherId === teacherId)
    : students;
  const selectedStudent = students.find((item) => item._id === studentId);
  const studentInvoices = invoices.filter(
    (invoice) => invoice.studentId === studentId,
  );

  useLayoutEffect(() => {
    if (defaultValues?.teacherId) {
      setTeacher(String(defaultValues?.teacherId));
    }
    if (defaultValues?.studentId) {
      setStudent(String(defaultValues?.studentId));
    }
  }, []);

  const methods = useForm<IInvoiceCreateApi>({
    defaultValues: {
      typeOfPayment: "cash",
      ...defaultValues,
    },
  });

  const onSelectTeacher = (value: number | string) => {
    setTeacher(String(value));
    setStudent("");
    methods.setValue("studentId", "");
  };

  const onClearTeacher = () => {
    setTeacher("");
    setStudent("");
    methods.setValue("studentId", "");
  };

  const onSelectStudent = (value: number | string) => {
    setStudent(String(value));
    const selectedStudent = students.find((item) => item._id === value);
    const selectedTeacher = selectedStudent?.teacherId
      ? String(selectedStudent?.teacherId)
      : "";
    setTeacher(selectedTeacher);
    methods.setValue("teacherId", selectedTeacher);
  };

  useEffect(() => {
    if (newPaidDates.length) {
      methods.clearErrors("paidDates");
    }
  }, [newPaidDates.length]);

  const onSubmit: SubmitHandler<IInvoiceCreateApi> = async (data) => {
    if (!newPaidDates.length) {
      methods.setError("paidDates", {
        message: "Обовʼязково виберіть дати оплати",
      });
    } else {
      await handleSubmit(data);
    }
  };

  const paidDates = studentInvoices
    .map((item) => item.paidDates)
    .flat()
    .filter((item) => {
      return !defaultValues?.paidDates?.find((newPaidDate) =>
        dayjs(newPaidDate).isSame(dayjs(item), "day"),
      );
    });

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputsCalendarContainer>
            <InputsContainer>
              <Autocomplete
                name="teacherId"
                label="Ім'я вчителя"
                placeholder="Ім'я вчителя"
                rules={{ required: "Обов'язкове поле" }}
                options={teachers.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                handleClearProp={onClearTeacher}
                handleChangeProp={onSelectTeacher}
              />
              <Autocomplete
                // isDisabled={!teacherId}
                name="studentId"
                label="Ім'я учня"
                placeholder="Ім'я учня"
                rules={{ required: "Обов'язкове поле" }}
                options={filteredStudents.map((item) => ({
                  label: item.name,
                  value: item._id,
                }))}
                handleClearProp={() => setStudent("")}
                handleChangeProp={onSelectStudent}
              />
              <Select
                name="typeOfPayment"
                label="Тип оплати"
                placeholder="Тип оплати"
                rules={{ required: "Обов'язкове поле" }}
                selectOptions={typeOfPaymentOptions}
              />
              <Input
                name="amount"
                type="number"
                label="Сума"
                placeholder="Сума"
                rules={{ required: "Обов'язкове поле" }}
              />
              <Datepicker
                name="dateOfPayment"
                label="Дата платежу"
                placeholder="Дата платежу"
                rules={{ required: "Обов'язкове поле" }}
              />
            </InputsContainer>
            <FormInvoiceCalendar
              label="За які дні оплата"
              isDisabled={!selectedStudent}
              onChange={onChangeNewPaidDates}
              visitingDays={selectedStudent?.visitingDays}
              visitedDates={selectedStudent?.visitedDates}
              paidDates={paidDates}
              newPaidDates={newPaidDates}
            />
          </InputsCalendarContainer>

          <SubmitButton label="Зберегти" type="submit" />
        </form>
      </FormProvider>
    </Container>
  );
};

export { FormInvoice };
