import { createApi } from "@reduxjs/toolkit/query/react";

import { ISignInApi, ISignInResponseApi } from "./interfaces";
import { baseQueryWithReauth } from "./base-query";

export const busyBeeApiAuth = createApi({
  reducerPath: "busy-bee-api-auth",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    signIn: builder.mutation<ISignInResponseApi, ISignInApi>({
      query: (body) => ({
        url: `auth/sign-in`,
        method: "POST",
        body,
      }),
    }),
    getSchool: builder.query<ISignInResponseApi, void>({
      query: () => ({
        url: `auth/get-school`,
        method: "GET",
      }),
    }),
  }),
});

export const { useSignInMutation, useLazyGetSchoolQuery } = busyBeeApiAuth;
