import { FC } from "react";
import { Container, Text } from "./invoices-day-summary-styles";
import { IInvoice } from "../../../interface";

interface IProps {
  invoices: IInvoice[];
}

const InvoicesDaySummary: FC<IProps> = ({ invoices }) => {
  const allSummary = invoices.reduce((acc, item) => acc + item.amount, 0);
  const unknownSummary = invoices.reduce(
    (acc, item) => (item.typeOfPayment ? acc : acc + item.amount),
    0,
  );
  const cardSummary = invoices.reduce(
    (acc, item) => (item.typeOfPayment === "card" ? acc + item.amount : acc),
    0,
  );
  const cashSummary = invoices.reduce(
    (acc, item) => (item.typeOfPayment === "cash" ? acc + item.amount : acc),
    0,
  );
  const FOPSummary = invoices.reduce(
    (acc, item) => (item.typeOfPayment === "FOP" ? acc + item.amount : acc),
    0,
  );

  return (
    <Container>
      <Text>
        <b>Всього:</b> {allSummary} грн.
      </Text>
      {!!cardSummary && (
        <Text>
          <b>Карткою:</b> {cardSummary} грн.
        </Text>
      )}
      {!!FOPSummary && (
        <Text>
          <b>ФОП:</b> {FOPSummary} грн.
        </Text>
      )}
      {!!cashSummary && (
        <Text>
          <b>Готівкою:</b> {cashSummary} грн.
        </Text>
      )}
      {!!unknownSummary && (
        <Text>
          <b>Не зазначено:</b> {unknownSummary} грн.
        </Text>
      )}
    </Container>
  );
};

export { InvoicesDaySummary };
