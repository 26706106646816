import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Container = styled.ul<{ className: string }>``;

export const Item = styled.li<{ className: string }>`
  padding: 18px 16px;
  & > div {
    width: 30%;
  }

  @media ${device.tablet} {
    padding: 14px 12px;
    & > div:first-child {
      width: 60%;
    }
    & > div {
      width: auto;
    }
  }
`;

export const NameContainer = styled.div`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 16px;
`;

export const StudentsCountContainer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
`;

export const DateContainer = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  text-align: end;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;
