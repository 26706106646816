import React, { FC } from "react";
import { CalendarContainer } from "./student-item-calendar-styles";
import Calendar from "react-calendar";
import dayjs from "dayjs";

interface IProps {
  value?: Date;
  visitingDays: string[];
  visitedDates: Date[];
  paidDates: Date[];
  selectedInvoicePaidDates?: Date[];
  onClickDate: (date: Date) => void;
}

const StudentItemCalendar: FC<IProps> = ({
  value,
  visitingDays,
  visitedDates,
  paidDates,
  selectedInvoicePaidDates,
  onClickDate,
}) => {
  const tileClassName = ({ date }: { date: Date }) => {
    const isVisited = visitedDates.find((item) =>
      dayjs(date).isSame(item, "day"),
    );
    const isPaid = paidDates.find((item) => dayjs(date).isSame(item, "day"));
    const isSelectedPaid = selectedInvoicePaidDates?.find((item) =>
      dayjs(date).isSame(item, "day"),
    );
    const isVisiting = !visitingDays.includes(
      dayjs(date).locale("en").format("dd"),
    );
    return `${isVisited ? "visited" : ""} ${isPaid ? "paid" : ""} ${isSelectedPaid ? "selected_paid" : ""} ${isVisiting ? "disabled" : "visiting"}`;
  };

  return (
    <CalendarContainer>
      <Calendar
        locale="uk"
        defaultActiveStartDate={value}
        onClickDay={onClickDate}
        tileClassName={tileClassName}
      />
    </CalendarContainer>
  );
};

export { StudentItemCalendar };
