import { IStudentApi } from "../../services/busy-bee-api/interfaces";

export interface IStudent extends IStudentApi {
  teacher: string;
}

export enum DebtStatus {
  All = "",
  IsDebt = "is-debt",
  IsDebtWithRedDays = "is-debt-with-red-days",
  IsNotDebt = "is-not-debt",
}
