import styled from "styled-components";
import { hexToRGB } from "../../../../common/utils/hex-to-rgb";

export const CalendarContainer = styled.div`
  .react-calendar {
    border: none;
  }
  .react-calendar__navigation {
    margin-bottom: 0;
  }

  .disabled {
    background-color: ${hexToRGB("#808080", 0.7)} !important;
  }
  .visiting {
    color: black;
    background-color: ${hexToRGB("#ff0000", 0.4)} !important;
  }
  .visited {
    color: black;
    background-color: ${hexToRGB("#ece000", 1)} !important;
  }
  .paid {
    color: black;
    background-color: ${hexToRGB("#2abd00", 0.7)} !important;
  }
  .selected_paid {
    color: white;
    background-color: ${hexToRGB("#218000", 1)} !important;
  }
`;
