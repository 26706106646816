import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  isModalAddStudentOpen: boolean;
  isModalEditStudentOpen: string;
  isModalStudentItemOpen: string;
}

const initialState: IState = {
  isModalAddStudentOpen: false,
  isModalEditStudentOpen: "",
  isModalStudentItemOpen: "",
};

export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    openModalAddStudent(state) {
      state.isModalAddStudentOpen = true;
    },
    closeModalAddStudent(state) {
      state.isModalAddStudentOpen = false;
    },
    toggleModalEditStudent(state, action: PayloadAction<string>) {
      state.isModalEditStudentOpen = action.payload;
    },
    toggleModalStudentItem(state, action: PayloadAction<string>) {
      state.isModalStudentItemOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openModalAddStudent,
  closeModalAddStudent,
  toggleModalEditStudent,
  toggleModalStudentItem,
} = studentsSlice.actions;

export const { name, reducer } = studentsSlice;

export default studentsSlice;
