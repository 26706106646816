import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFormLogin } from "../interface";

interface IState {
  user?: { name: string; expUser: string };
  accessToken: string;
}

const initialState: IState = {
  user: undefined,
  accessToken: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<IFormLogin>) {
      state.user = {
        name: action.payload.username,
        expUser: new Date().toISOString(),
      };
    },
    logoutUser(state) {
      state.user = undefined;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser, setAccessToken } = authSlice.actions;

export const { name, reducer } = authSlice;

export default authSlice;
