import React, { FC, useState } from "react";
import dayjs from "dayjs";

import { ReactComponent as ArrowLeftIcon } from "assets/streamline-light/arrows-diagrams/arrows/arrow-left-1.svg";
import { ReactComponent as ArrowRightIcon } from "assets/streamline-light/arrows-diagrams/arrows/arrow-right-1.svg";

import {
  CalendarContainer,
  Container,
  DateContainer,
  DateText,
} from "./invoices-list-filters-styles";
import { IconButton } from "../../../../common/components/form/icon-button";
import { SecondaryButton } from "../../../../common/components/form/secondary-button";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import { OutsideClickHandler } from "../../../../common/components/outside-click-handler";
// import { SelectSecondaryButton } from "../../../common/components/form/select-secondary-button/select-secondary-button";
// import { FormProvider, useForm } from "react-hook-form";

interface IProps {
  day: number;
  setDay: React.Dispatch<React.SetStateAction<number>>;
}

const InvoicesListFilters: FC<IProps> = ({ day, setDay }) => {
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const value = dayjs().add(day, "day");

  const onChangeDate = (type: "next" | "prev") => {
    if (type === "next") {
      setDay((item) => item + 1);
    } else {
      setDay((item) => item - 1);
    }
  };

  const onClickDate = (value: Value) => {
    const diff = -dayjs()
      .startOf("day")
      .diff(dayjs(String(value)), "day");
    setDay(diff);
    setIsOpenCalendar(false);
  };

  const onTodayClick = () => {
    setDay(0);
  };

  // const methods = useForm();
  return (
    <Container>
      {/*<FormProvider {...methods}>*/}
      {/*  <SelectSecondaryButton*/}
      {/*    name="list-type"*/}
      {/*    selectOptions={[*/}
      {/*      { label: "test", value: "test" },*/}
      {/*      { label: "test 1", value: "test 1" },*/}
      {/*    ]}*/}
      {/*  />*/}
      {/*</FormProvider>*/}
      <DateContainer>
        <SecondaryButton
          onClick={onTodayClick}
          isDisabled={day === 0}
          label="Сьогоднi"
        />
        <IconButton
          buttonColorHover={"#3c58c0"}
          buttonColor={"#4E73F5"}
          icon={ArrowLeftIcon}
          onClick={() => onChangeDate("prev")}
        />
        <DateText onClick={() => setIsOpenCalendar(!isOpenCalendar)}>
          {value.format("dddd DD-MM-YYYY")}
        </DateText>
        <IconButton
          buttonColorHover={"#3c58c0"}
          buttonColor={"#4E73F5"}
          icon={ArrowRightIcon}
          onClick={() => onChangeDate("next")}
        />
        {isOpenCalendar && (
          <CalendarContainer>
            <OutsideClickHandler
              onOutsideClick={() => setIsOpenCalendar(false)}
            >
              <Calendar
                locale="uk"
                onChange={onClickDate}
                value={value.toISOString()}
              />
            </OutsideClickHandler>
          </CalendarContainer>
        )}
      </DateContainer>
    </Container>
  );
};

export { InvoicesListFilters };
