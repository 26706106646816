import styled from "styled-components";
import { hexToRGB } from "../../../../common/utils/hex-to-rgb";
import { device } from "../../../../../style/theme";

export const Container = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .react-calendar {
    border: ${({ isDisabled }) =>
      isDisabled ? "1px solid #d9d9d9" : "1px solid #a8a8a7"};
    border-radius: 5px;

    @media ${device.tablet} {
      max-width: 350px;
      width: 100%;
    }
  }

  .react-calendar__navigation__label {
    pointer-events: none;
  }

  .disabled {
    background-color: ${hexToRGB("#808080", 0.7)} !important;
  }
  .visiting {
    color: black;
    background-color: ${hexToRGB("#ff0000", 0.4)} !important;
  }
  .visited {
    color: black;
    background-color: ${hexToRGB("#ece000", 1)} !important;
  }
  .paid {
    pointer-events: none;
    color: black;
    background-color: ${hexToRGB("#2abd00", 0.7)} !important;
  }
  .new_paid {
    pointer-events: auto;
    background-color: ${hexToRGB("#005cff", 0.4)} !important;
  }
`;

export const Title = styled.h4`
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.7)};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ErrorContainer = styled.div`
  order: 3;
  margin-top: 4px;
  display: flex;
  width: 100%;
`;

export const Spacer = styled.div`
  flex: 1;
  //padding-right: 16px;

  @media ${device.tablet} {
    display: none;
  }
`;

export const ErrorMessage = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.red};
`;
