import { FC } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-edit-student-styles";
import { FormStudent } from "../form-student/form-student";
import {
  useGetStudentsQuery,
  useUpdateStudentMutation,
} from "../../../../services/busy-bee-api/busy-bee-api-students";
import { IStudentCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { toggleModalEditStudent } from "../../store/students-slice";
import {
  formStudentGetParentsFromValues,
  useFormStudentParents,
} from "../../hooks/useFormStudentParents";

const mobileWrapperStyles = {
  width: "100%",
  minHeight: "100%",
};

const ModalEditStudent: FC = () => {
  const dispatch = useDispatch();
  const isMobileSize = useIsMobileSize();
  const { isModalEditStudentOpen } = useAppSelector((state) => state.students);

  const { data: studentsRes } = useGetStudentsQuery();
  const students = studentsRes || [];
  const student = students.find((item) => item._id === isModalEditStudentOpen);

  const { parentsState, removeParent, addParent } = useFormStudentParents({
    isModalAddStudentOpen: !!isModalEditStudentOpen,
    initialState: student?.parents?.map((item, i) => `${i}`),
  });

  let studentDefaultValues = { ...student };
  student?.parents.forEach((parent, i) => {
    // @ts-ignore
    studentDefaultValues[`parent-name-${i}`] = parent.name;
    // @ts-ignore
    studentDefaultValues[`parent-phone-${i}`] = parent.phone;
  });

  const onClose = () => {
    dispatch(toggleModalEditStudent(""));
  };

  const [mutation] = useUpdateStudentMutation();
  const onAddStudent = async (values: IStudentCreateApi) => {
    const parents = formStudentGetParentsFromValues(values, parentsState);
    await mutation({
      _id: isModalEditStudentOpen,
      name: values.name,
      phone: values.phone ?? "",
      teacherId: values.teacherId,
      parents: parents || [],
      visitingDays: values.visitingDays || [],
      visitedDates: values.visitedDates || [],
      isVPO: values.isVPO,
      isUBD: values.isUBD,
    });
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={!!isModalEditStudentOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : {}}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Редагувати учня</Title>
        <FormStudent
          // @ts-ignore
          defaultValues={studentDefaultValues}
          handleSubmit={onAddStudent}
          parents={parentsState}
          addParent={addParent}
          removeParent={removeParent}
        />
      </Container>
    </Modal>
  );
};

export { ModalEditStudent };
