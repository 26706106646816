import { FC, ReactElement } from "react";
import { GreenButton } from "../green-button";
import { Header } from "./content-layout-styles";

interface IProps {
  name: string;
  content: ReactElement;
  button?: {
    label: string;
    onClick: () => void;
  };
}

const ContentLayout: FC<IProps> = ({ name, content, button }) => {
  return (
    <>
      <header className="bg-white shadow">
        <Header className=" max-w-7xl justify-between">
          <h1 className="text-3xl font-bold tracking-tight text-gray-900 w-fit">
            {name}
          </h1>
          {button && (
            <GreenButton label={button.label} onClick={button.onClick} />
          )}
        </Header>
      </header>
      <main>
        <div className="mx-auto max-w-7xl lg:py-6 sm:py-2 sm:px-6 lg:px-8">
          {content}
        </div>
      </main>
    </>
  );
};

export { ContentLayout };
