import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface IProps {
  itemsCount: number;
  pageSize: number;
  initPage?: number;
}

const usePagination = ({ itemsCount, pageSize, initPage }: IProps) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  const [currentPage, setCurrentPage] = useState(initPage ?? 1);
  const history = useNavigate();
  const location = useLocation();

  const onChangePage = (
    type: "back" | "next" | "page",
    pageNumber?: number,
  ) => {
    let page;
    if (type === "page" && pageNumber && pageNumber <= pagesCount) {
      page = pageNumber;
      setCurrentPage(pageNumber);
    } else if (type === "next" && pagesCount >= currentPage + 1) {
      page = currentPage + 1;
      setCurrentPage(currentPage + 1);
    } else if (type === "back" && 1 <= currentPage - 1) {
      page = currentPage - 1;
      setCurrentPage(currentPage - 1);
    }
    const params = new URLSearchParams({ page: String(page) });
    history({ pathname: location.pathname, search: params.toString() });
  };

  return { currentPage, pagesCount, onChangePage };
};

export { usePagination };
