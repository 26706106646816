import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";

import { IFormLogin } from "../../interface";
import { Input } from "../../../common/components/form/input/input";
import { Error, FormContainer, SubmitButton } from "./login-form-styles";
import { setAccessToken } from "../../store/auth-slice";
import { useSignInMutation } from "../../../../services/busy-bee-api/busy-bee-api-auth";
import { ISignInResponseApi } from "../../../../services/busy-bee-api/interfaces";

const LoginForm: FC = () => {
  const [signIn] = useSignInMutation();
  const methods = useForm<IFormLogin>();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const onSubmit = async ({ username, password }: IFormLogin) => {
    try {
      const res = await signIn({ username, password });
      const ress = res as { data: ISignInResponseApi };
      if (ress?.data.accessToken) {
        dispatch(setAccessToken(ress.data.accessToken));
        localStorage.setItem("accessToken", ress.data.accessToken);
      }
    } catch {
      setError("Логін або пароль невірні");
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <FormContainer>
          <Input name="username" placeholder="Логін" rules={{}} />
          <Input
            type="password"
            name="password"
            placeholder="Пароль"
            rules={{}}
          />
          {error && <Error>{error}</Error>}
          <SubmitButton
            label="Увійти"
            onClick={methods.handleSubmit(onSubmit)}
          />
        </FormContainer>
      </FormProvider>
    </>
  );
};

export { LoginForm };
