import styled, { CSSProperties } from "styled-components";

interface IStyledIcon {
  color?: string;
  fillColor?: string;
  width?: number;
  height?: number;
  isRotate?: boolean;
  strokeWidth?: string;
}

interface IProps extends IStyledIcon {
  id?: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
  style?: CSSProperties;
  onClick?: () => void;
}

const StyledIcon = styled.i<IStyledIcon>`
  transform: ${({ isRotate }) => (isRotate ? "rotate(180deg)" : "")};
  transition: 500ms;
  ${(props) => props.strokeWidth && `stroke-width: ${props.strokeWidth}px;`}
  ${(props) => `
  display: block;
  width: ${props.width ?? 24}px;
  height: ${props.height ?? 24}px;
  > svg {
    display: block;
    width: 100%;
    height: auto;
    stroke: ${props.color ?? "#fff"};
    > path {
      stroke: ${props.color ?? "#fff"};
      ${props.fillColor && `fill: ${props.fillColor}`}
    }
    > rect {
      stroke: ${props.color ?? "#fff"};
      ${props.fillColor && `fill: ${props.fillColor}`}
    }
    > g path {
      stroke: ${props.color ?? "#fff"};
      ${props.fillColor && `fill: ${props.fillColor}`}
    }
  }
  `}
`;

const Icon = ({
  id,
  icon: BaseIcon,
  style,
  onClick,
  ...rest
}: IProps): JSX.Element => {
  return (
    <StyledIcon
      id={id}
      style={style}
      className="icon"
      onClick={onClick}
      {...rest}
    >
      <BaseIcon />
    </StyledIcon>
  );
};

export default Icon;
