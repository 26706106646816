import styled, { css } from "styled-components";

const square = "26px";
const offset = "30px";
const duration = "1.5s";
const delay = "0.2s";
const timingFunction = "ease-in-out";
const inDuration = "0.4s";
const inDelay = "0.1s";
const inTimingFunction = "ease-out";

export const Container = styled.div<{ isLoading: boolean }>`
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isLoading }) =>
    isLoading
      ? ""
      : css`
          display: none;
        `}
`;

// --square: 26px;
// --offset: 30px;
// --duration: 1.5s;
// --delay: 0.2s;
// --timing-function: ease-in-out;
// --in-duration: 0.4s;
// --in-delay: 0.1s;
// --in-timing-function: ease-out;

export const LoadingSpinner = styled.div`
  width: calc(3 * ${offset} + ${square});
  height: calc(2 * ${offset} + ${square});
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;

  div {
    display: inline-block;
    background: darkorange;
    /*background: var(--text-color);*/
    /*box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);*/
    border: none;
    border-radius: 2px;
    width: ${square};
    height: ${square};
    position: absolute;
    padding: 0;
    margin: 0;
    font-size: 6pt;
    color: black;
  }

  #square1 {
    left: calc(0 * ${offset});
    top: calc(0 * ${offset});
    animation:
      square1 ${duration} ${delay} ${timingFunction} infinite,
      squarefadein ${inDuration} calc(1 * ${inDelay}) ${inTimingFunction} both;
  }
  #square2 {
    left: calc(0 * ${offset});
    top: calc(1 * ${offset});
    animation:
      square2 ${duration} ${delay} ${timingFunction} infinite,
      squarefadein ${inDuration} calc(1 * ${inDelay}) ${inTimingFunction} both;
  }
  #square3 {
    left: calc(1 * ${offset});
    top: calc(1 * ${offset});
    animation:
      square3 ${duration} ${delay} ${timingFunction} infinite,
      squarefadein ${inDuration} calc(2 * ${inDelay}) ${inTimingFunction} both;
  }
  #square4 {
    left: calc(2 * ${offset});
    top: calc(1 * ${offset});
    animation:
      square4 ${duration} ${delay} ${timingFunction} infinite,
      squarefadein ${inDuration} calc(3 * ${inDelay}) ${inTimingFunction} both;
  }
  #square5 {
    left: calc(3 * ${offset});
    top: calc(1 * ${offset});
    animation:
      square5 ${duration} ${delay} ${timingFunction} infinite,
      squarefadein ${inDuration} calc(4 * ${inDelay}) ${inTimingFunction} both;
  }
  @keyframes square1 {
    0% {
      left: calc(0 * ${offset});
      top: calc(0 * ${offset});
    }

    8.333% {
      left: calc(0 * ${offset});
      top: calc(1 * ${offset});
    }

    100% {
      left: calc(0 * ${offset});
      top: calc(1 * ${offset});
    }
  }

  @keyframes square2 {
    0% {
      left: calc(0 * ${offset});
      top: calc(1 * ${offset});
    }

    8.333% {
      left: calc(0 * ${offset});
      top: calc(2 * ${offset});
    }

    16.67% {
      left: calc(1 * ${offset});
      top: calc(2 * ${offset});
    }

    25.00% {
      left: calc(1 * ${offset});
      top: calc(1 * ${offset});
    }

    83.33% {
      left: calc(1 * ${offset});
      top: calc(1 * ${offset});
    }

    91.67% {
      left: calc(1 * ${offset});
      top: calc(0 * ${offset});
    }

    100% {
      left: calc(0 * ${offset});
      top: calc(0 * ${offset});
    }
  }

  @keyframes square3 {
    0%,
    100% {
      left: calc(1 * ${offset});
      top: calc(1 * ${offset});
    }

    16.67% {
      left: calc(1 * ${offset});
      top: calc(1 * ${offset});
    }

    25.00% {
      left: calc(1 * ${offset});
      top: calc(0 * ${offset});
    }

    33.33% {
      left: calc(2 * ${offset});
      top: calc(0 * ${offset});
    }

    41.67% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }

    66.67% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }

    75.00% {
      left: calc(2 * ${offset});
      top: calc(2 * ${offset});
    }

    83.33% {
      left: calc(1 * ${offset});
      top: calc(2 * ${offset});
    }

    91.67% {
      left: calc(1 * ${offset});
      top: calc(1 * ${offset});
    }
  }

  @keyframes square4 {
    0% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }

    33.33% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }

    41.67% {
      left: calc(2 * ${offset});
      top: calc(2 * ${offset});
    }

    50.00% {
      left: calc(3 * ${offset});
      top: calc(2 * ${offset});
    }

    58.33% {
      left: calc(3 * ${offset});
      top: calc(1 * ${offset});
    }

    100% {
      left: calc(3 * ${offset});
      top: calc(1 * ${offset});
    }
  }

  @keyframes square5 {
    0% {
      left: calc(3 * ${offset});
      top: calc(1 * ${offset});
    }

    50.00% {
      left: calc(3 * ${offset});
      top: calc(1 * ${offset});
    }

    58.33% {
      left: calc(3 * ${offset});
      top: calc(0 * ${offset});
    }

    66.67% {
      left: calc(2 * ${offset});
      top: calc(0 * ${offset});
    }

    75.00% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }

    100% {
      left: calc(2 * ${offset});
      top: calc(1 * ${offset});
    }
  }

  @keyframes squarefadein {
    0% {
      transform: scale(0.75);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

export const ContainerChildren = styled.div<{ isLoading: boolean }>`
  min-height: 100svh;

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: none;
        `
      : ""}
`;
