import { useState } from "react";
import { sorts } from "../../common/constants/sorts";
import { ITeacher } from "../interface";
import dayjs from "dayjs";
import {
  IStudentApi,
  ITeacherApi,
} from "../../../services/busy-bee-api/interfaces";

interface IProps {
  teachers: ITeacherApi[];
  students: IStudentApi[];
}

const useFilterSortTeachers = ({ teachers, students }: IProps) => {
  const [filters, setFilters] = useState({ name: "" });
  const [sort, setSort] = useState(sorts[0]); // А-Я, Я-А

  if (filters.name) {
    teachers = teachers.filter((item) =>
      item.name.toLowerCase().includes(filters.name.toLowerCase()),
    );
  }

  let teachersFiltered: ITeacher[] = teachers.map((item) => ({
    ...item,
    studentsCount: students.filter((student) => student.teacherId === item._id)
      .length,
  }));

  if (sort === sorts[0]) {
    teachersFiltered = teachersFiltered.sort((a, b) =>
      dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1,
    );
  } else {
    const sortedByName = teachersFiltered.sort(function (a, b) {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    if (sort === sorts[1]) {
      teachersFiltered = sortedByName;
    } else {
      teachersFiltered = sortedByName.reverse();
    }
  }

  return {
    teachers: teachersFiltered,
    setFilters,
    setSort,
  };
};

export { useFilterSortTeachers };
