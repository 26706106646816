import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  isModalAddInvoiceOpen: boolean;
  isModalAddInvoicesOpen: boolean;
  isModalEditInvoiceOpen: string;
  selectedInvoice: string;
}

const initialState: IState = {
  isModalAddInvoiceOpen: false,
  isModalAddInvoicesOpen: false,
  isModalEditInvoiceOpen: "",
  selectedInvoice: "",
};

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    openModalAddInvoice(state) {
      state.isModalAddInvoiceOpen = true;
    },
    closeModalAddInvoice(state) {
      state.isModalAddInvoiceOpen = false;
    },
    openModalAddInvoices(state) {
      state.isModalAddInvoicesOpen = true;
    },
    closeModalAddInvoices(state) {
      state.isModalAddInvoicesOpen = false;
    },
    toggleModalEditInvoice(state, action: PayloadAction<string>) {
      state.isModalEditInvoiceOpen = action.payload;
    },
    selectInvoice(state, action: PayloadAction<string>) {
      state.selectedInvoice = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  openModalAddInvoice,
  closeModalAddInvoice,
  openModalAddInvoices,
  closeModalAddInvoices,
  toggleModalEditInvoice,
  selectInvoice,
} = invoicesSlice.actions;

export const { name, reducer } = invoicesSlice;

export default invoicesSlice;
