import styled from "styled-components";
import Icon from "../icon";
// import { ButtonLoader } from './button-loader';
import { device } from "../../../../style/theme";

interface IBaseProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  iconColor?: string;
  buttonColor?: string;
  buttonColorHover?: string;
  border?: string;
  isLoading?: boolean;
  iconColorHover?: string;
  buttonWidth?: string;
  tabletButtonWidth?: string;
  tabletButtonHeight?: string;
}

interface IProps extends IBaseProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >;
}

const StyledButton = styled.button<IBaseProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  border: ${(props) => props.border ?? "none"};
  box-shadow: none;
  outline: none;

  width: ${(props) => props.buttonWidth ?? "32px"};
  height: 32px;
  border-radius: 5px;

  cursor: pointer;
  color: ${(props) => props.color ?? "#fff"};
  background: ${(props) => props.buttonColor ?? props.theme.ctaBlue};
  text-decoration: none;
  opacity: 1;
  transition: background-color 225ms ease;
  &:hover {
    background-color: ${(props) =>
      props.buttonColorHover ?? props.theme.ctaBlueHover};
    path {
      stroke: ${(props) => props.iconColorHover ?? props.iconColor};
    }
  }
  > div {
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${device.tablet} {
    width: ${(props) => props.tabletButtonWidth ?? "32px"};
    height: ${(props) => props.tabletButtonHeight ?? "32px"};
  }
`;

const IconButton = ({
  icon,
  iconColor,
  isLoading,
  ...rest
}: IProps): JSX.Element => (
  <StyledButton type="button" {...rest}>
    {/*{isLoading ? (*/}
    {/*  <ButtonLoader*/}
    {/*    isLoading={isLoading ?? false}*/}
    {/*    color={iconColor}*/}
    {/*    isDisabled={false}*/}
    {/*  />*/}
    {/*) : (*/}
    <Icon width={16} height={16} icon={icon} color={iconColor} />
    {/*)}*/}
  </StyledButton>
);

export { IconButton };
