import { FC } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-invoice-mutation-styles";
import { useAddInvoiceMutation } from "../../../../services/busy-bee-api/busy-bee-api-invoices";
import { IInvoiceCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { closeModalAddInvoices } from "../../store/invoices-slice";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { FormInvoice } from "../form-invoice/form-invoice";
import { useFormInvoiceNewPaidDates } from "../../hooks/useFormInvoiceNewPaidDates";

const mobileWrapperStyles = {
  width: "100%",
  minHeight: "100%",
};

const desktopWrapperStyles = {
  maxWidth: "750px",
};

interface IProps {
  dateOfPayment: Date;
}

const ModalAddInvoice: FC<IProps> = ({ dateOfPayment }) => {
  const dispatch = useAppDispatch();
  const { isModalAddInvoicesOpen } = useAppSelector((state) => state.invoices);

  const { newPaidDates, onChangeNewPaidDates } = useFormInvoiceNewPaidDates({
    isModalOpen: isModalAddInvoicesOpen,
  });

  const defaultValues = {
    dateOfPayment,
  };

  const onClose = () => {
    dispatch(closeModalAddInvoices());
  };

  const isMobileSize = useIsMobileSize();
  const [createInvoice] = useAddInvoiceMutation();

  const onAddInvoice = async (values: IInvoiceCreateApi) => {
    onClose();
    const invoice = {
      typeOfPayment: values.typeOfPayment,
      amount: values.amount,
      dateOfPayment: values.dateOfPayment,
      paidDates: newPaidDates,
      teacherId: values.teacherId,
      studentId: values.studentId,
    };
    await createInvoice(invoice);
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={isModalAddInvoicesOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : desktopWrapperStyles}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Створити платіж</Title>
        <FormInvoice
          defaultValues={defaultValues}
          newPaidDates={newPaidDates}
          onChangeNewPaidDates={onChangeNewPaidDates}
          handleSubmit={onAddInvoice}
        />
      </Container>
    </Modal>
  );
};

export { ModalAddInvoice };
