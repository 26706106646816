import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from "react";
import { RegisterOptions, useController } from "react-hook-form";

import { ReactComponent as CloseIcon } from "assets/streamline-light/interface-essential/form-validation/close.svg";

import {
  AutocompleteClearContainer,
  Container,
  ErrorContainer,
  ErrorMessage,
  InfoWrapper,
  InputAdornment,
  InputField,
  InputHide,
  InputWrapper,
  Label,
  LabelContainer,
  OptionItem,
  OptionsContainer,
  OptionText,
  Spacer,
  SubLabel,
  Wrapper,
} from "./autocomplete-styles";
import { OutsideClickHandler } from "../../outside-click-handler";
import Icon from "../../icon";
import { defaultTheme } from "../../../../../style/theme";

interface IProps {
  name: string;
  label?: string;
  styleGuideErr?: boolean;
  sublabel?: string;
  placeholder?: string;
  backgroundColor?: string;
  valueProp?: string | number;
  rules?: RegisterOptions;
  defaultValue?: unknown;
  type?: string;
  autoComplete?: "on" | "off" | "nope" | "false" | "new-password";
  onFocus?: () => void;
  isAutoFocus?: boolean;
  onBlur?: () => void;
  inputWrapperChildren?: ReactNode;
  onOutsideClick?: () => void;
  isDisabled?: boolean;
  isDisabledWhite?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  isPlaceholderCenter?: boolean;
  isLabelSaturated?: boolean;
  isNoBorder?: boolean;
  isDecimalNumber?: boolean;
  isSaturatedBorder?: boolean;
  inputAdornment?: string;
  handleChangeProp?: (value: number | string) => void;
  handleClearProp?: () => void;
  options: { label: string; value: string }[];
}

const Autocomplete: FC<IProps> = ({
  label,
  styleGuideErr,
  sublabel,
  name,
  valueProp,
  placeholder,
  backgroundColor,
  rules,
  defaultValue,
  autoComplete = "on",
  onFocus,
  isAutoFocus,
  onOutsideClick,
  inputWrapperChildren,
  isLabelSaturated,
  isDisabled,
  isDisabledWhite,
  isReadOnly,
  isRequired,
  isPlaceholderCenter,
  isNoBorder,
  isDecimalNumber,
  isSaturatedBorder,
  inputAdornment,
  handleChangeProp,
  handleClearProp,
  options,
}) => {
  const {
    field: { ref, value: valueController, onChange, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: rules?.value ?? defaultValue,
  });
  const value = valueProp || valueController;

  const [isOpen, setIsOpen] = useState(false);

  // input logic
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(valueController);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const isValue =
    value !== "" &&
    value !== undefined &&
    value !== null &&
    !Number.isNaN(value);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        onOutsideClick &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, onOutsideClick]);

  const errorMessage = errors[name] ? String(errors[name]?.message) : "";

  const onSelect = (label: string, value: string) => {
    onChange(value);
    setInputValue(label);
    setIsOpen(false);
    if (handleChangeProp) {
      handleChangeProp(value);
    }
  };

  useEffect(() => {
    setInputValue(options.find((item) => item.value === value)?.label || "");
  }, [options]);

  const filteredOptions = options.filter((item) =>
    item.label?.toLowerCase().includes(inputValue?.toLowerCase() || ""),
  );

  const onClear = () => {
    onChange("");
    setInputValue("");
    if (handleClearProp) {
      handleClearProp();
    }
  };

  return (
    <Container className="input-container" hasError={!!errors[name]}>
      <Wrapper className="input-wrapper" ref={wrapperRef}>
        <InfoWrapper>
          {label && (
            <LabelContainer className="label-container">
              <Label
                className="label"
                styleGuideErr={styleGuideErr}
                isLabelSaturated={isLabelSaturated}
              >
                {label}
                {isRequired && "*"}
              </Label>
              {sublabel && <SubLabel>{sublabel}</SubLabel>}
            </LabelContainer>
          )}
        </InfoWrapper>
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <InputWrapper>
            <InputHide
              type="number"
              id={name}
              ref={ref}
              value={value}
              onChange={() => {}}
            />
            <InputField
              id={`${name}-autocomplete`}
              ref={inputRef}
              isInputAdornment={!!inputAdornment}
              placeholder={placeholder ?? ""}
              type="text"
              autoComplete={autoComplete}
              onFocus={() => {
                if (onFocus) {
                  onFocus();
                }
                setIsOpen(true);
              }}
              autoFocus={isAutoFocus}
              onChange={onInputChange}
              hasError={!!errors[name]}
              styleGuideErr={styleGuideErr}
              backgroundColor={backgroundColor}
              isNoBorder={isNoBorder}
              isValue={isValue}
              isDisabled={isDisabled}
              isDisabledWhite={isDisabledWhite}
              isPlaceholderCenter={isPlaceholderCenter}
              readOnly={isReadOnly}
              isSaturatedBorder={isSaturatedBorder}
              value={inputValue}
              {...inputProps}
            />
            {inputValue && (
              <AutocompleteClearContainer onClick={onClear}>
                <Icon
                  icon={CloseIcon}
                  color={defaultTheme.grey2}
                  width={11}
                  height={11}
                />
              </AutocompleteClearContainer>
            )}
            {inputAdornment && (
              <InputAdornment>{inputAdornment}</InputAdornment>
            )}
            {isOpen && (
              <OptionsContainer>
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((item) => (
                    <OptionItem
                      onClick={() => onSelect(item.label, item.value)}
                    >
                      <OptionText>{item.label}</OptionText>
                    </OptionItem>
                  ))
                ) : (
                  <OptionText>Запис не знайдено</OptionText>
                )}
              </OptionsContainer>
            )}
          </InputWrapper>
        </OutsideClickHandler>
        {inputWrapperChildren}
      </Wrapper>

      {errorMessage && (
        <ErrorContainer className="field-error">
          <Spacer />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { Autocomplete };
