import { FC, useLayoutEffect, useState } from "react";

import { ReactComponent as CloseIcon } from "assets/streamline-light/interface-essential/form-validation/close.svg";

import {
  Container,
  ParentsContainer,
  SubmitButton,
  AddParentButton,
  ParentInputsContainer,
  ParentContainer,
} from "./form-student-styles";
import { Input } from "../../../common/components/form/input/input";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { Autocomplete } from "../../../common/components/form/autocomplete/autocomplete";
import { useGetTeachersQuery } from "../../../../services/busy-bee-api/busy-bee-api-teachers";
import { IStudentCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { BoxSelectorLine } from "../../../common/components/form/box-selector-line/box-selector-line";
import { IconButton } from "../../../common/components/form/icon-button";
import { defaultTheme } from "../../../../style/theme";
import { Checkbox } from "../../../common/components/form/checkbox";

interface IProps {
  parents: string[];
  addParent: () => void;
  removeParent: (i: number) => void;
  defaultValues?: IStudentCreateApi;
  handleSubmit: (values: IStudentCreateApi) => Promise<void>;
}

const days = [
  { value: "Mo", text: "Пн" },
  { value: "Tu", text: "Вт" },
  { value: "We", text: "Ср" },
  { value: "Th", text: "Чт" },
  { value: "Fr", text: "Пт" },
  { value: "Sa", text: "Сб" },
  { value: "Su", text: "Нд" },
];

const FormStudent: FC<IProps> = ({
  parents,
  addParent,
  removeParent,
  defaultValues,
  handleSubmit,
}) => {
  const methods = useForm<IStudentCreateApi>({ defaultValues });

  const { data: teachersRes } = useGetTeachersQuery();
  const teachers = teachersRes || [];
  const [teacher, setTeacher] = useState("");

  useLayoutEffect(() => {
    if (defaultValues?.teacherId) {
      setTeacher(String(defaultValues.teacherId));
    }
  }, []);

  const onSubmit: SubmitHandler<IStudentCreateApi> = async (data) => {
    await handleSubmit({ ...data, parents: [] });
  };

  return (
    <Container>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Autocomplete
            name="teacherId"
            label="Ім'я вчителя"
            placeholder="Ім'я вчителя"
            rules={{ required: "Обов'язкове поле" }}
            options={teachers.map((item) => ({
              label: item.name,
              value: item._id,
            }))}
            handleChangeProp={(value) => setTeacher(String(value))}
            handleClearProp={() => setTeacher("")}
          />
          <Input
            isDisabled={!teacher}
            name="name"
            label="Ім'я учня"
            placeholder="Ім'я учня"
            rules={{ required: "Обов'язкове поле" }}
          />
          <Input
            isDisabled={!teacher}
            name="phone"
            type="tel"
            label="Номер учня"
            placeholder="Номер учня"
          />
          <BoxSelectorLine
            isDisabled={!teacher}
            name="visitingDays"
            label="Дні відвідування"
            boxes={days}
            rules={{ required: "Обов'язкове поле" }}
          />
          <Checkbox name="isVPO" label="ВПО" />
          <Checkbox name="isUBD" label="УБД" />
          {parents.length > 0 && (
            <ParentsContainer>
              {parents.map((parent, i) => {
                return (
                  <ParentContainer key={i}>
                    <ParentInputsContainer>
                      <Input
                        name={`parent-name-${i}`}
                        label="Ім'я родича"
                        placeholder="Ім'я родича"
                        rules={{ required: "Обов'язкове поле" }}
                      />
                      <Input
                        type="tel"
                        name={`parent-phone-${i}`}
                        label="Номер родича"
                        placeholder="Номер родича"
                        rules={{ required: "Обов'язкове поле" }}
                      />
                    </ParentInputsContainer>
                    {i + 1 === parents.length && (
                      <IconButton
                        iconColor={defaultTheme.blue}
                        icon={CloseIcon}
                        onClick={() => removeParent(i)}
                      />
                    )}
                  </ParentContainer>
                );
              })}
            </ParentsContainer>
          )}
          <AddParentButton
            type="button"
            label="Додати родича"
            onClick={addParent}
            isDisabled={!teacher}
          />

          <SubmitButton label="Зберегти" type="submit" />
        </form>
      </FormProvider>
    </Container>
  );
};

export { FormStudent };
