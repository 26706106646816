import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Container = styled.div`
  padding: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.borderColor};
  flex-wrap: wrap;
  gap: 12px;
  & > div {
    max-width: 280px;
    min-width: 280px;

    @media ${device.tablet} {
      max-width: 100%;
      min-width: 100%;
    }
  }
`;
