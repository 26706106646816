import { createApi } from "@reduxjs/toolkit/query/react";

import {
  IStudentApi,
  IStudentCreateApi,
  IStudentUpdateApi,
} from "./interfaces";
import { baseQueryWithReauth } from "./base-query";
import dayjs from "dayjs";

export const busyBeeApiStudents = createApi({
  reducerPath: "busy-bee-api",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Students"],
  endpoints: (builder) => ({
    getStudents: builder.query<IStudentApi[], void>({
      query: () => `students`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ _id }) => ({ type: "Students", _id }) as const),
              { type: "Students", id: "LIST" },
            ]
          : [{ type: "Students", id: "LIST" }],
      transformResponse: (baseQueryReturnValue: IStudentApi[]) => {
        return baseQueryReturnValue.map((item) => {
          const sortedDates = item.visitedDates
            .map((item) => new Date(item))
            .sort((a, b) => (dayjs(a).isAfter(dayjs(b), "day") ? 1 : -1));

          return {
            ...item,
            visitedDates: sortedDates,
          };
        });
      },
    }),
    getStudent: builder.query<IStudentApi, string>({
      query: (id) => `students/${id}`,
      providesTags: (result, error, _id) => [{ type: "Students", _id }],
    }),
    addStudent: builder.mutation<IStudentApi, IStudentCreateApi>({
      query: (body) => ({
        url: `/students`,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "Students", id: "LIST" }],
    }),
    updateStudent: builder.mutation<IStudentApi, IStudentUpdateApi>({
      query: ({ _id, ...patch }) => ({
        url: `/students/${_id}`,
        method: "POST",
        body: patch,
      }),
      invalidatesTags: (result, error, { _id }) => [{ type: "Students", _id }],
    }),
    removeStudent: builder.mutation<{ success: boolean; id: string }, string>({
      query: (id) => ({
        url: `/students/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, _id) => [{ type: "Students", _id }],
    }),
  }),
});

export const {
  useAddStudentMutation,
  useGetStudentQuery,
  useGetStudentsQuery,
  useRemoveStudentMutation,
  useUpdateStudentMutation,
} = busyBeeApiStudents;
