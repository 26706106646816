import {
  CSSProperties,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
} from "react";
import ReactModal from "react-modal";
import styled, { css } from "styled-components";
import { hexToRGB } from "../../utils/hex-to-rgb";
import { device } from "../../../../style/theme";

interface IModal {
  isOpen: boolean;
  isHiddenScroll?: boolean;
  overlayStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
  onClose: (e?: React.SyntheticEvent) => void;
  children: ReactNode;
}

// const Container = styled.div`
//   .ReactModal__Content {
//     background: none !important;
//     border: none !important;
//     height: 100%;
//     width: 100%;
//   }
// `;

const Overlay = styled.div`
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => hexToRGB(props.theme.logoBlue, 0.9)};
  z-index: 2010;
  display: table-cell;
  vertical-align: middle;
`;

const ModalWrapper = styled.div<{
  isHiddenScroll?: boolean;
}>`
  margin: auto;
  max-width: 688px;
  width: 100%;
  //padding: 32px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.white};

  ${({ isHiddenScroll }) =>
    isHiddenScroll &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    `}
  @media ${device.tablet} {
    min-height: fit-content;
    /* padding: 20px; */
    /* min-height: 100vh; */
  }
`;

const Modal = ({
  isOpen,
  isHiddenScroll,
  overlayStyles,
  wrapperStyles,
  onClose,
  children,
}: IModal): JSX.Element => {
  const onEscKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== "Escape") {
        return;
      }

      onClose();
    },
    [onClose],
  );

  const onModalWrapperClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
    },
    [],
  );

  useEffect(() => {
    window.addEventListener("keydown", onEscKeyDown);

    return () => {
      window.removeEventListener("keydown", onEscKeyDown);
    };
  }, [onClose, onEscKeyDown]);

  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        overlay: { overflowY: "auto", overflowX: "hidden" },
        content: {
          border: "none",
          background: "none",
          display: "table",
          height: "100%",
          width: "100%",
          padding: 0,
          inset: 0,
        },
      }}
      onRequestClose={onClose}
    >
      <Overlay style={overlayStyles} onClick={onClose}>
        <ModalWrapper
          className={isOpen ? "modal-open" : "modal-close"}
          style={wrapperStyles}
          onClick={onModalWrapperClick}
          isHiddenScroll={isHiddenScroll}
        >
          {children}
        </ModalWrapper>
      </Overlay>
    </ReactModal>
  );
};

export { Modal };
