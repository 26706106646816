import { useEffect, useState } from "react";
import dayjs from "dayjs";

interface IProps {
  isModalOpen: boolean;
  defaultPaidDates?: Date[];
}

const useFormInvoiceNewPaidDates = ({
  isModalOpen,
  defaultPaidDates,
}: IProps) => {
  const [newPaidDates, setNewPaidDates] = useState<Date[]>(
    defaultPaidDates ?? [],
  );

  const onChangeNewPaidDates = (value: Date) => {
    if (newPaidDates.find((item) => dayjs(item).isSame(dayjs(value), "day"))) {
      setNewPaidDates(
        newPaidDates
          .filter((item) => !dayjs(item).isSame(dayjs(value), "day"))
          .sort(),
      );
    } else {
      const now = dayjs();
      const nowHour = now.get("hour");
      const nowMinute = now.get("minute");
      setNewPaidDates(
        [
          ...newPaidDates,
          dayjs(value).set("hour", nowHour).set("minute", nowMinute).toDate(),
        ].sort(),
      );
    }
  };

  useEffect(() => {
    if (defaultPaidDates && defaultPaidDates?.length > 0) {
      setNewPaidDates(defaultPaidDates);
    }
  }, [defaultPaidDates?.length]);

  useEffect(() => {
    if (!isModalOpen) {
      setNewPaidDates([]);
    }
  }, [isModalOpen]);

  return {
    newPaidDates,
    onChangeNewPaidDates,
  };
};

export { useFormInvoiceNewPaidDates };
