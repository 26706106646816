import React, { FC } from "react";
import { ContentLayout } from "../../common/components/content-layout/content-layout";
import { Pagination } from "../../common/components/pagination";
import { usePagination } from "../../common/hooks/usePagination";
import { StudentsList } from "../components/students-list/students-list";
import { useQuery } from "../../common/hooks/useQuery";
import { StudentsListFilters } from "../components/students-list-filters/students-list-filters";
import { useDispatch } from "react-redux";
import { ModalAddStudent } from "../components/modal-add-student/modal-add-student";
import { openModalAddStudent } from "../store/students-slice";
import { ModalEditStudent } from "../components/modal-edit-student/modal-edit-student";
import { ModalStudentItem } from "../components/modal-student-item/modal-student-item";
import { DataLoader } from "../../common/components/data-loader/data-loader";
import { IStudent } from "../interface";
import { useFilterSortStudents } from "../hooks/useFilterSortStudents";
import { useGetAllResources } from "../../../services/busy-bee-api/hooks/useGetAllResources";

const StudentsPage: FC = () => {
  const dispatch = useDispatch();

  const {
    students: studentsList,
    teachers,
    invoices,
    isLoading,
  } = useGetAllResources();

  const onAddNew = () => {
    dispatch(openModalAddStudent());
  };

  const pageSize = 10;
  const query = useQuery();
  const page = query.get("page");

  const { students, setSort, setFilters } = useFilterSortStudents({
    students: studentsList,
    teachers,
    invoices,
  });

  const { currentPage, pagesCount, onChangePage } = usePagination({
    itemsCount: students.length,
    pageSize,
    initPage: Number(page) || undefined,
  });

  const onSetFilters: React.Dispatch<
    React.SetStateAction<{
      name: string;
      teacherName: string;
      debtStatus: string;
    }>
  > = (state) => {
    setFilters(state);
    onChangePage("page", 1);
  };

  const studentsPage = students.filter((item, i) => {
    const from = pageSize * currentPage - pageSize + 1;
    const to = pageSize * currentPage;
    i = i + 1;
    return i >= from && i <= to;
  }) as IStudent[];

  return (
    <ContentLayout
      name="Учні"
      button={{
        label: "Створити учня",
        onClick: onAddNew,
      }}
      content={
        <>
          <StudentsListFilters setFilters={onSetFilters} setSort={setSort} />

          {isLoading ? (
            <DataLoader />
          ) : (
            <>
              <StudentsList students={studentsPage} />
              <Pagination
                itemsCount={students.length}
                currentPage={currentPage}
                pagesCount={pagesCount}
                pageSize={pageSize}
                onChangePage={onChangePage}
              />
            </>
          )}

          <ModalAddStudent />
          <ModalEditStudent />
          <ModalStudentItem />
        </>
      }
    />
  );
};

export { StudentsPage };
