import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding: 0 14px 14px;
`;

export const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
`;
