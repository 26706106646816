import styled from "styled-components";
import { device } from "../../../../style/theme";

export const Container = styled.div`
  position: relative;
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.tablet} {
    height: 100%;
    padding: 40px 18px 18px;
  }
`;

export const Title = styled.h3`
  color: ${({ theme }) => theme.blue};
  font-family: Roboto, sans-serif;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 12px;
`;
