import { FC } from "react";
import { Container, Loader } from "./data-loader-styles";

const DataLoader: FC = () => {
  return (
    <Container>
      <Loader />
    </Container>
  );
};

export { DataLoader };
