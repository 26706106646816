import { useEffect, useState } from "react";
import { IStudentCreateApi } from "../../../services/busy-bee-api/interfaces";

interface IProps {
  isModalAddStudentOpen: boolean;
  initialState?: string[];
}

const useFormStudentParents = ({
  isModalAddStudentOpen,
  initialState,
}: IProps) => {
  const [parents, setParents] = useState<string[]>(initialState ?? []);
  useEffect(() => {
    if (!isModalAddStudentOpen) {
      setParents([]);
    }
  }, [isModalAddStudentOpen]);

  useEffect(() => {
    setParents(initialState ?? []);
  }, [initialState?.length]);

  const addParent = () => {
    setParents([...parents, String(parents.length)]);
  };
  const removeParent = (i: number) => {
    setParents(parents.filter((item, index) => index !== i));
  };

  return {
    parentsState: parents,
    addParent,
    removeParent,
  };
};

const formStudentGetParentsFromValues = (
  values: IStudentCreateApi,
  parentsState: string[],
) => {
  const parentNamesKeys = Object.keys(values)
    .filter((item) => item.includes("parent-name-"))
    .filter((item, i) => i < parentsState.length);

  return parentNamesKeys.map((item, i) => {
    // @ts-ignore
    const name = values[item];
    // @ts-ignore
    const phone = values[`parent-phone-${i}`];

    return {
      name,
      phone,
    };
  });
};

export { useFormStudentParents, formStudentGetParentsFromValues };
