import { FC, useEffect } from "react";
import { Modal } from "../../../common/components/modal/modal";
import { useIsMobileSize } from "../../../common/hooks/useIsMobileSize";
import { CloseButton } from "../../../common/components/modal/close-button";
import { defaultTheme } from "../../../../style/theme";
import { Container, Title } from "./modal-invoice-mutation-styles";
import {
  useGetInvoicesQuery,
  useUpdateInvoiceMutation,
} from "../../../../services/busy-bee-api/busy-bee-api-invoices";
import { IInvoiceCreateApi } from "../../../../services/busy-bee-api/interfaces";
import { useAppDispatch } from "../../../common/hooks/useAppDispatch";
import { useAppSelector } from "../../../common/hooks/useAppSelector";
import { toggleModalEditInvoice } from "../../store/invoices-slice";
import { FormInvoice } from "../form-invoice/form-invoice";
import { useFormInvoiceNewPaidDates } from "../../hooks/useFormInvoiceNewPaidDates";

const mobileWrapperStyles = {
  width: "100%",
  minHeight: "100%",
};

const desktopWrapperStyles = {
  maxWidth: "750px",
};

const ModalEditInvoice: FC = () => {
  const dispatch = useAppDispatch();
  const { isModalEditInvoiceOpen } = useAppSelector((state) => state.invoices);

  const { data } = useGetInvoicesQuery();
  const invoices = data || [];
  const invoice = invoices.find((item) => item._id === isModalEditInvoiceOpen);

  const { newPaidDates, onChangeNewPaidDates } = useFormInvoiceNewPaidDates({
    isModalOpen: !!isModalEditInvoiceOpen,
    defaultPaidDates: invoice?.paidDates,
  });

  useEffect(() => {
    if (data && !invoice) {
      onClose();
    }
  }, [invoice]);

  const onClose = () => {
    dispatch(toggleModalEditInvoice(""));
  };

  const isMobileSize = useIsMobileSize();
  const [mutation] = useUpdateInvoiceMutation();

  const onEditInvoice = async (values: IInvoiceCreateApi) => {
    await mutation({
      _id: isModalEditInvoiceOpen,
      dateOfPayment: values.dateOfPayment,
      paidDates: newPaidDates,
      teacherId: values.teacherId,
      typeOfPayment: values.typeOfPayment,
      amount: values.amount,
      studentId: values.studentId,
    });
    onClose();
  };

  return (
    <Modal
      onClose={onClose}
      isOpen={!!isModalEditInvoiceOpen}
      wrapperStyles={isMobileSize ? mobileWrapperStyles : desktopWrapperStyles}
    >
      <Container>
        <CloseButton
          handleClose={onClose}
          color={defaultTheme.black}
          position="right"
        />
        <Title>Редагувати платіж</Title>
        <FormInvoice
          defaultValues={invoice}
          newPaidDates={newPaidDates}
          onChangeNewPaidDates={onChangeNewPaidDates}
          handleSubmit={onEditInvoice}
        />
      </Container>
    </Modal>
  );
};

export { ModalEditInvoice };
