import React, { FC } from "react";
import { Container, Text } from "./student-item-selected-paid-day-styles";
import dayjs from "dayjs";
import { IInvoiceApi } from "../../../../../services/busy-bee-api/interfaces";

interface IProps {
  selectedInvoice: IInvoiceApi;
}

const StudentItemSelectedPaidDay: FC<IProps> = ({ selectedInvoice }) => {
  const selectedInvoiceTypeOfPayment = !selectedInvoice?.typeOfPayment
    ? ""
    : selectedInvoice.typeOfPayment === "cash"
      ? "Готівкою"
      : selectedInvoice.typeOfPayment === "FOP"
        ? "ФОП"
        : "Карткою";

  return (
    <Container>
      {/*<SubTitle>{selectedPaidDay.format("DD.MM.YYYY")}</SubTitle>*/}
      <Text>
        <b>Дата платежу: </b>
        {dayjs(selectedInvoice.dateOfPayment).format("HH:mm DD.MM.YYYY")}
      </Text>
      <Text>
        <b>Оплачені дати: </b>
        {selectedInvoice.paidDates.map(
          (item, i) =>
            dayjs(item).format("DD.MM.YYYY") +
            (i + 1 === selectedInvoice.paidDates.length ? ". " : ",") +
            " ",
        )}
      </Text>
      <Text>
        <b>Тип оплати:</b>{" "}
        {selectedInvoiceTypeOfPayment
          ? selectedInvoiceTypeOfPayment
          : "Не зазначено"}
      </Text>
      <Text>
        <b>Сума:</b> {selectedInvoice?.amount} грн
      </Text>
    </Container>
  );
};

export { StudentItemSelectedPaidDay };
