import { FC, Fragment } from "react";
import { useController } from "react-hook-form";
import {
  Container,
  Wrapper,
  ErrorContainer,
  ErrorMessage,
  InfoWrapper,
  Label,
  LabelContainer,
  Spacer,
  SubLabel,
  BoxesWrapper,
} from "./box-selector-line-styles";
import { IProps } from "./interface";
import { BoxItem } from "./box-item/box-item";

const BoxSelectorLine: FC<IProps> = ({
  name,
  boxes,
  label,
  sublabel,
  defaultValue,
  onClick,
  styleGuideErr,
  isOldDesign,
  isWrapper,
  isColumn,
  rules,
  isLabelSaturated,
  isDisabled,
  isRequired,
  handleChangeProp,
}) => {
  const {
    field: { ref, value, onChange },
    formState: { errors },
  } = useController({
    name,
    rules,
    defaultValue: defaultValue || [],
  });

  const onSelectBox =
    <T,>(v: T, isDisabledCustomOnClick?: boolean) =>
    () => {
      if (value && value.includes(v)) {
        onChange(value.filter((item: string) => item !== v));
      } else {
        onChange([...value, v]);
      }
      if (onClick && !isDisabledCustomOnClick) {
        onClick();
      }
      if (handleChangeProp) {
        handleChangeProp();
      }
    };

  const errorMessage = errors[name] ? String(errors[name]?.message) : "";

  return (
    <Container hasError={!!errors[name]} isWrapper={isWrapper} ref={ref}>
      <Wrapper isColumn={isColumn}>
        <InfoWrapper>
          {label && (
            <LabelContainer>
              <Label
                isOldDesign={isOldDesign}
                styleGuideErr={styleGuideErr}
                isLabelSaturated={isLabelSaturated}
              >
                {label}
                {isRequired && "*"}
              </Label>
              {sublabel && <SubLabel>{sublabel}</SubLabel>}
            </LabelContainer>
          )}
        </InfoWrapper>
        <BoxesWrapper isColumn={isColumn}>
          {boxes.map((box) => (
            <Fragment key={box.value}>
              <BoxItem
                text={box.text}
                isSelected={value.includes(box.value)}
                isDisabled={isDisabled}
                onClick={onSelectBox(box.value, box.isDisabledCustomOnClick)}
              />
            </Fragment>
          ))}
        </BoxesWrapper>
      </Wrapper>

      {errorMessage && (
        <ErrorContainer>
          <Spacer />
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorContainer>
      )}
    </Container>
  );
};

export { BoxSelectorLine };
