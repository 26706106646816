import { FC } from "react";
import styled from "styled-components";
import { hexToRGB } from "../../utils/hex-to-rgb";
import { useController } from "react-hook-form";

const Container = styled.div`
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  .cbx span:first-child .cbx:hover span:last-child {
    border-color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
  }

  .inp-cbx:checked + .cbx span:last-child {
    border-color: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    background: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    animation: check-15 0.6s ease;
  }
  .inp-cbx:checked + .cbx span:last-child svg {
    stroke-dashoffset: 0;
  }
  .inp-cbx:checked + .cbx span:last-child:before {
    transform: scale(2.2);
    opacity: 0;
    transition: all 0.6s ease;
  }

  @keyframes check-15 {
    50% {
      transform: scale(1.2);
    }
  }
`;

const CheckboxInput = styled.input``;

const CheckboxLabel = styled.label`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  //justify-content: space-between;
  cursor: pointer;
`;

const SVGContainer = styled.span`
  display: inline-block;
  vertical-align: middle;

  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #b9b8c3;
  transition: all 0.2s ease;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background: ${(props) => hexToRGB(props.theme.blue, 0.8)};
    display: block;
    transform: scale(0);
    opacity: 1;
    border-radius: 50%;
    transition-delay: 0.2s;
  }

  svg {
    position: absolute;
    z-index: 1;
    top: 8px;
    left: 6px;
    fill: none;
    stroke: white;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 16px;
    stroke-dashoffset: 16px;
    transition: all 0.3s ease;
    transition-delay: 0.1s;
    transform: translate3d(0, 0, 0);
  }
`;

const CheckboxText = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: ${({ theme }) => hexToRGB(theme.blue, 0.7)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
`;

interface IProps {
  name: string;
  label: string;
  defaultValue?: boolean;
}

const Checkbox: FC<IProps> = ({ name, label, defaultValue }) => {
  const {
    field: { ref, value: valueController, onChange, onBlur, ...inputProps },
    formState: { errors },
  } = useController({
    name,
    defaultValue,
  });

  return (
    <Container className="checkbox-wrapper-15">
      <CheckboxInput
        ref={ref}
        className="inp-cbx"
        id={name}
        name={name}
        checked={valueController}
        onChange={onChange}
        type="checkbox"
        style={{ display: "none" }}
      />
      <CheckboxLabel className="cbx" htmlFor={name}>
        <CheckboxText>{label}</CheckboxText>
        <SVGContainer>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </SVGContainer>
      </CheckboxLabel>
    </Container>
  );
};

export { Checkbox };
