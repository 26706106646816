import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

export const Text = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.regularText};
`;
