import styled from "styled-components";
import { device } from "../../../style/theme";

export const Container = styled.div`
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;

  * {
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
  }

  @media ${device.tablet} {
    align-items: flex-start;
  }
`;

export const CardGroup = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media ${device.tablet} {
    flex-direction: column-reverse;
    height: 100%;
    justify-content: center;
    background: white;
  }
`;

export const FormCard = styled.div`
  padding: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;
  border-radius: 4px 0 0 4px;

  @media ${device.tablet} {
    border-radius: 0;
    border: none;
    padding-top: 0;
  }
`;

export const FormHeader = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  line-height: 1.2;
  font-size: 36px;
  color: #000;

  @media ${device.tablet} {
    font-size: 32px;
  }
`;

export const FormDescription = styled.div`
  color: rgba(44, 56, 74, 0.681);
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
`;

export const LogoCard = styled.div`
  background-color: #fff;
  border-radius: 0 4px 4px 0;
  padding: 64px 40px 64px 0;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-left: none;

  @media ${device.tablet} {
    border-radius: 0;
    border: none;
    padding: 0;
  }
`;

export const Logo = styled.img`
  width: 200px;
  height: 200px;
`;
